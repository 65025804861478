import { useState, useEffect } from "react"
import _ from "lodash";

import { createCitation, getAllCitations } from "../services/reportServices"

export default function CitationLibrary({ libraryPanel, setLibraryPanel, bookDetailsCitationFormat, addCitation }) {

    const [citations, setCitations] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        if (libraryPanel) {
            getAllCitations()
            .then((response) => {
                setCitations(response.data.result.userCitations);
                setLoader(false);
            })
            .catch(error => {
                setCitations([]);
                setLoader(false);
            })
        } else {
            setLoader(false);
        }
    }, [])

    const createCitationBlock = (citation) => {
        const authors = JSON.parse(citation.authors);
        const citation_id = 'citation-'+_.random(10000, 99999);
        let author_display_name = '';

        let str_concat = ', ';
        let name_concat = ' and ';
        let skip_year = false;

        if (bookDetailsCitationFormat == "APA 7" && bookDetailsCitationFormat == "Default") {
            name_concat = ' & ';
        }

        if (bookDetailsCitationFormat == "Chicago") {
            str_concat = ' ';
        }

        if (bookDetailsCitationFormat == "MLA 9") {
            skip_year = true;
            str_concat = '';
        }

        if (authors.length > 0) {
            if (authors.length == 2) {
                author_display_name = authors[0].display_name +name_concat+ authors[1].display_name;
                author_display_name += skip_year ? '' : str_concat+ citation.publication_year;
            }

            if (authors.length == 1) {
                author_display_name = authors[0].display_name;
                author_display_name += skip_year ? '' : str_concat+ citation.publication_year;
            }

            if (authors.length > 2) {
                author_display_name = authors[0].display_name +' et al.';
                author_display_name += skip_year ? '' : str_concat+ citation.publication_year;
            }
        } else {
            author_display_name = '...';
        }

        let authors_str = '( '+author_display_name+' )';

        let createCitationFunc = async () => await createCitation(citation_id, citation.author_display_name, citation.user_id, citation.title, citation.keywords, citation.doi_link, citation.publication_date, citation.publication_year, citation.cited_by_count, citation.source_organization_name, citation.source_name, citation.source_type, citation.authors, citation.volume_no, citation.issue_no, citation.first_page, citation.last_page, citation.data_json);
        createCitationFunc().catch((error) => console.log(error))
        addCitation(citation_id, authors_str)
    }

    return (
        <div className="col-lg-3 px-0 min-vh-100 position-fixed end-0" style={{ background: 'rgb(242 244 246)', zIndex: '999' }}>
            {loader && 
                <div className="position-absolute py-5 top-0 h-100 w-100 bg-light" style={{ zIndex: 1 }}>
                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
                    <p className="fs-10px my-2">Fetching Data ...</p>
                </div>
            }
            <div className="ps-3 pe-2">
                <h5 className="my-3 text-start px-1">Manage Citation
                    <button onClick={(e) => setLibraryPanel(false)} className="btn theme-btn4 fs-10px py-1 px-2 float-end">Close <i className="mdi mdi-close"></i></button>
                </h5>
                <hr />
                <div className="overflow-scroll pe-1" style={{ height: 'calc(100vh - 160px)' }}>
                    {citations.length > 0 && citations.map((citation, index) => (
                        <div className="card mb-3 border-0 shadow-sm" key={index}>
                            <div className="card-body text-start">
                                <p className="fs-14px fw-medium mb-1 lh-base">{citation.title}</p>
                                <p className="fs-10px fw-normal mb-0 lh-base text-capitalize"><span className="fw-medium">author display name: </span></p>
                                <p className="fs-10px fw-normal mb-1 lh-base text-capitalize">{citation.author_display_name}</p>
                                <p className="fs-10px fw-normal mb-0 lh-base text-capitalize"><span className="fw-medium">source name: </span></p>
                                <p className="fs-10px fw-normal mb-1 lh-base text-capitalize">{citation.source_name}</p>
                                <p className="fs-10px fw-normal mb-0 lh-base text-capitalize"><span className="fw-medium">source organization name: </span></p>
                                <p className="fs-10px fw-normal mb-1 lh-base text-capitalize">{citation.source_organization_name}</p>
                                <p className="fs-10px fw-normal mb-0 lh-base text-capitalize"><span className="fw-medium">source type: </span></p>
                                <p className="fs-10px fw-normal mb-1 lh-base text-capitalize">{citation.source_type}</p>
                                <div className="mt-2">
                                    <a href={`/`} target="_blank" className="btn theme-btn5 fs-10px my-2 me-2 px-2 py-1"> <span className="mdi mdi-link me-1 ms-0"></span> Preview</a>
                                    <span onClick={(e) => createCitationBlock(citation)} className="btn theme-btn6 fs-10px my-2 me-2 px-2 py-1"> <span className="mdi mdi-plus me-1 ms-0"></span> Add Citation</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    {citations.length == 0 && 
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <p className="fs-12px fw-semibold">No citations found</p>
                                <p className="fs-12px">You can cite your text using selection tool and search for the new citation in the global library.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}