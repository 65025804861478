import img1 from '../../assets/ai_writer_pop_bg1.jpg'
import img2 from '../../assets/ai_writer_pop_bg2.jpg'

export function EditorIntroModal ({ editorIntroModal, setEditorIntroModal, setPlanDocumentModal, setDocumentDescModal, triggerFile, user }) {

    const skipToEditor = () => {
        setEditorIntroModal(false)
        setDocumentDescModal(true);
    }

    const openLoreBook = () => {
        setEditorIntroModal(false)
        setPlanDocumentModal(true)
    }

    return (
        <div className={`modal ${editorIntroModal ? "show" : ""}`} style={{ background: "rgb(0 0 0 / 59%)", backdropFilter: "blur(10px)" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header" style={{background:"#ffffff"}}>
                        <h5 className="modal-title mx-auto" id="exampleModalToggleLabel">Welcome to Manuscripts.ai!</h5>
                        <button type="button" className="btn" onClick={()=>setEditorIntroModal(false)}> <i className="mdi fs-4 mdi-close"></i> </button>
                    </div>
                    <div className="modal-body">
                        <div className="row text-start px-3">
                            <div className="col-lg-6 my-3 border-end">
                                <div className="card p-2 border-0">
                                    <div className="card-body">
                                        <h5 className="text-center card-title">Want to write?</h5>
                                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                        <img src={img2} className="img-fluid rounded opacity-75" alt="" />
                                        <div className='mt-3 text-center d-flex flex-wrap justify-content-center gap-3'>
                                            {user.user.user_mode == 'Author' &&
                                                <span onClick={(e) => openLoreBook()} className="theme-btn5 px-3 fs-12px fw-medium cursor-pointer py-2"><span className="mdi me-1 mdi-creation"></span> AI Lore Book</span>
                                            }
                                            <span onClick={(e) => skipToEditor()} className="theme-btn5 px-3 fs-12px fw-medium cursor-pointer py-2"><span className="mdi me-1 mdi-square-edit-outline"></span> Editor Mode</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 my-3">
                                <div className="card p-2 border-0">
                                    <div className="card-body">
                                        <h5 className="text-center card-title">Want to edit?</h5>
                                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                        <img src={img1} className="img-fluid rounded opacity-75" alt="" />
                                        <div className='mt-3 text-center d-flex flex-wrap justify-content-center gap-3'>
                                            <span onClick={(e) => triggerFile(e)} className="theme-btn5 px-3 fs-12px fw-medium cursor-pointer py-2"><span className="mdi me-1 mdi-file-upload-outline"></span> Upload your Book</span>
                                            <span onClick={(e) => skipToEditor()} className="theme-btn5 px-3 fs-12px fw-medium cursor-pointer py-2"><span className="mdi me-1 mdi-square-edit-outline"></span> Editor Mode</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}