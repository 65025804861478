import { useState, useEffect } from "react"
import _ from "lodash";
import { getAnalyticsHistory } from "../services/reportServices";

export default function HistoryPanel({ historyPanel, setHistoryPanel, docId }) {
    const [historyList, setHistoryList] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        setHistoryList([])
        if (historyPanel) {
            getAnalyticsHistory(docId)
            .then((response) => {
                if (response.data.result.length > 0) {
                    setHistoryList(response.data.result)
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setHistoryList([])
                    setLoader(false);
                }
            }).catch((error) => {
                setHistoryList([])
                setLoader(false);
            })
        } else {
            setLoader(false);
        }
    }, [])

    const copyToClipboard = (chapter) => {
        navigator.clipboard.writeText(chapter);
    }

    return (
        <div className="col-lg-3 px-0 min-vh-100 position-fixed end-0" style={{ background: 'rgb(242 244 246)', zIndex: '999' }}>
            {loader && 
                <div className="position-absolute py-5 top-0 h-100 w-100 bg-light" style={{ zIndex: 1 }}>
                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
                    <p className="fs-10px my-2">Fetching Data ...</p>
                </div>
            }
            <div className="ps-3 pe-2">
                <h5 className="my-3 text-start px-1">History
                    <button onClick={(e) => setHistoryPanel(false)} className="btn theme-btn4 fs-10px py-1 px-2 float-end">Close <i className="mdi mdi-close"></i></button>
                </h5>
                <hr />
                <div className="overflow-scroll pe-1" style={{ height: 'calc(100vh - 160px)' }}>
                    {historyList.length > 0 && historyList.map((history, index) => (
                        <div className="card mb-3 border-0 shadow-sm" key={index}>
                            <div className="card-body text-start">
                                <p className="fs-12px fw-medium mb-1 lh-base">Provided Content: </p>
                                <p className="fs-12px fw-normal mb-2 lh-base" dangerouslySetInnerHTML={{ __html: _.truncate(history.input, { length: 200 }) }}></p>
                                <p className="fs-12px fw-medium mb-1 lh-base">Generated Content: </p>
                                <p className="fs-12px fw-normal mb-0 lh-base" dangerouslySetInnerHTML={{ __html: _.truncate(history.output, { length: 200 }) }}></p>
                                <div className="mt-2">
                                    <span onClick={(e) => copyToClipboard(history.output)} className="btn theme-btn6 fs-10px my-2 me-2 px-2 py-1"> <span className="mdi mdi-content-copy me-1 ms-0"></span> Copy To Clipboard</span>
                                    {/* <a href={`/`} target="_blank" className="btn theme-btn5 fs-10px my-2 me-2 px-2 py-1"> <span className="mdi mdi-link me-1 ms-0"></span> Preview</a> */}
                                    {/* <span onClick={(e) => createCitation(citation.authors, citation.publication_year)} className="btn theme-btn6 fs-10px my-2 me-2 px-2 py-1"> <span className="mdi mdi-plus me-1 ms-0"></span> Add Citation</span> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    {!loader && historyList.length == 0 && 
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <p className="fs-12px fw-semibold">No history found</p>
                                <p className="fs-12px">Looks like you haven't used our AI powered services yet, You can start with your writing and editing journey.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}