import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Joi from "joi";
import logo from "./../../assets/logo.svg";
import supportImg from "./../../assets/girl-with-laptop.svg";
import Button3 from "../common/Buttons/Button3";
import { updatePasswordChange } from "../../services/authService";
import { handleHttpError } from "../../services/httpErrorService";

const UpdatePassword = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    otp: "",
    password: "",
    globalMessage: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let emailId = params.get("email");
    if (emailId != undefined && emailId !== "") {
      setEmail(emailId);
    }
  }, []);

  const updatePasswordVerify = (e) => {
    e.preventDefault();
    const errors = validateCredentials(email, otp, password);
    if (errors) return setErrors(errors);
    updatePasswordChange({ email, otp, password })
      .then((httpResponse) => {
        if (httpResponse.status == 200) {
          setErrors({ email: "", otp: "", globalMessage: "" });
          navigate("/login");
        }
      })
      .catch((httpResponse) => {
        let error = httpResponse.response;
        if (error.status == 400 || error.status == 500) {
          let { email, globalMessage, otp, password } = error.data.error || {};
          setErrors({
            email: email != undefined ? email : "",
            otp: otp != undefined ? otp : "",
            password: password != undefined ? password : "",
            globalMessage: globalMessage != undefined ? globalMessage : "",
          });
        }
        handleHttpError(error);
      });
  };

  const validateCredentials = (email, otp, password) => {
    const schema = Joi.object({
      email: Joi.string().min(3).max(30).required(),
      password: Joi.string()
        .min(3)
        .max(30)
        .regex(new RegExp(`[^\x00-\x7F]`), { invert: true })
        .required(),
      otp: Joi.number().min(6).required().label("OTP"),
    });
    const { error } = schema.validate(
      { email: email, password: password, otp: otp },
      { abortEarly: false }
    );
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  return (
    <div className="row" style={{ minHeight: "500px", height: "100vh" }}>
      <div className="col-6 p-5 text-start position-relative">
        <Link to="/">
          <img src={logo} className="img-fluid" alt="" />
        </Link>
        <h1 className="login_title mt-5 fs-2 ">Log In To Get Started</h1>
        <p>
          If you don’t have an account you can{" "}
          <Link to="/signup/" className="theme-link fs-14px">
            Sign Up
          </Link>
        </p>

        <img
          className="d-md-block d-none position-absolute bottom-0 start-0 end-0 mx-auto mb-4"
          src={supportImg}
          alt="manuscripts.ai boy with laptop"
          style={{ maxHeight: "calc(100vh - 270px)" }}
        />
      </div>
      <div className="col-6 p-5 form-container">
        <div className="form-container-inner">
          <form action="">
            <div className="row">
              <div className="col-12 text-start">
                <div className="mb-3">
                  <label className="form-label fs-12px mb-1 fw-medium">
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 fs-12px"
                    placeholder="OTP"
                    id="otp"
                    aria-describedby="otpHelp"
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  {errors?.otp && (
                    <div id="otpHelp" className="form-text text-danger">
                      {errors?.otp}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label fs-12px mb-1 fw-medium">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 fs-12px"
                    placeholder="Password"
                    id="password"
                    aria-describedby="passwordHelp"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors?.password && (
                    <div id="passwordHelp" className="form-text text-danger">
                      {errors?.password}
                    </div>
                  )}
                  {errors?.email && (
                    <div id="emailHelp" className="form-text text-danger">
                      {errors?.email}
                    </div>
                  )}
                  {errors?.globalMessage && (
                    <div
                      id="globalMessageHelp"
                      className="form-text text-danger"
                    >
                      {errors?.globalMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Button3
              onClick={(e) => updatePasswordVerify(e)}
              className="fs-14px"
            >
              Update Password
            </Button3>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
