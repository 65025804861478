import winkNLP from "wink-nlp";
import model from "wink-eng-lite-web-model";
import its from "wink-nlp/src/its";
import tokenizer from "sbd";
import axios from "axios";
import rake from "rake-js";
import _ from "lodash";
import { apiRouts } from "../utils/routes";
import { getData } from "./dataService";
import { getWords } from "./wordsServices";
import { ingVerbs } from "../utils/ingVerbs";
import { future } from "../utils/future";
import { glueWords } from "../utils/glueWords";
import { getNames } from "./NLPCompromiseServices";
import { pronounWords } from "../utils/pronounWords";
import { conjunctionWords } from "../utils/conjunctionWords";
import { replaceGrammarError, quillContentInsertBlotForGrammar } from "./highlightContent";

export const regexIndexOf = (string, regex, startpos) => {
  var indexOf = string.substring(startpos || 0).search(regex);
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
};

export const checkDifference = (oldText, newText) => {
  return axios.post(apiRouts.get_diff, { oldText: oldText, newText: newText }, headerOptions);
}

export const getSentenceRecommendation = (sentence) => {
  return axios.post(apiRouts.paraphrase_output, { input_string: sentence }, headerOptions);
}

export const searchString = (content, searchStr) => {
  let stringIndices = [],
    index = 0;
  while (
    (index = regexIndexOf(
      content,
      new RegExp(`\\b${searchStr}\\b`, "i"),
      index
    )) !== -1
  ) {
    stringIndices.push({
      stringStartIndex: index,
      stringEndIndex: index + searchStr.length,
      stringLenght: searchStr.length,
      word: searchStr,
    });
    index += searchStr.length;
  }
  return stringIndices;
};

export const searchStrArrFromContent = (content, stringArr) => {
  return new Promise((resolve, reject) => {
    try {
      let stringIndexes = [];
      stringArr.forEach((searcgString) => {
        let data = searchString(content, searcgString);
        if (data.length > 0) {
          stringIndexes = [...stringIndexes, ...data];
        }
      });
      resolve(stringIndexes);
    } catch (error) {
      reject(error);
    }
  });
};

export const searchStrArrFromContentWithWriteGood = (content, stringArr) => {
  return new Promise((resolve, reject) => {
    try {
      let stringIndexes = [];
      stringArr.forEach((searcgString) => {
        let data = searchString(content, searcgString);
        if (data.length > 0) {
          stringIndexes = [...stringIndexes, ...data];
        }
      });
      resolve(stringIndexes);
    } catch (error) {
      reject(error);
    }
  });
};

export const searchHomonymArrFromContent = (content, stringArr) => {
  return new Promise((resolve, reject) => {
    try {
      let stringIndexes = [];
      stringArr.forEach((searcgString) => {
        const mutliString = searcgString.split(",");
        mutliString.forEach((searchStr) => {
          if (searchStr !== " " || searchStr !== "undefined") {
            let data = searchString(content, searchStr);
            if (data.length > 0) {
              data = data.map((d) => {
                return {
                  ...d,
                  multiArr: mutliString,
                };
              });

              stringIndexes = [...stringIndexes, ...data];
            }
          }
        });
      });
      resolve(stringIndexes);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSentence = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  return sentences;
};

export const getSentenceVariation = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  const nlp = winkNLP(model);

  text = text.replace(/&nbsp;/g, " ");
  text = text.replace(/\r\n|\n/g, " ");
  text = text.replace(/\n/g, " ");
  let result = [];
  var startIndex = 0;
  var totalSentiment = 0;
  var totalWordCount = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);
    const plainText = sentence.replace(/<[^>]*>?/gm, "");
    const plainDoc = nlp.readDoc(plainText);
    var wordsCount = sentence.split(" ").length;
    totalWordCount += wordsCount;
    var classHighlight = "lessThen10";

    if (wordsCount < 10 && wordsCount != 0) classHighlight = "lessThen10";
    if (wordsCount >= 10 && wordsCount <= 19) classHighlight = "tenTo19";
    if (wordsCount >= 20 && wordsCount <= 29) classHighlight = "twentyTo29";
    if (wordsCount >= 30 && wordsCount <= 39) classHighlight = "thirtyTo39";
    if (wordsCount >= 40) classHighlight = "greaterThen40";

    if (startIndex == 0) {
      totalSentiment += plainDoc.out(its.sentiment);
      result.push({
        sentence: sentence,
        sentenceStartIndex: startIndex,
        sentenceEndIndex: startIndex + sentence.length,
        sentenceLength: sentence.length,
        wordsCount: wordsCount,
        score: plainDoc.out(its.sentiment),
        classHighlight: classHighlight,
      });
    } else {
      startIndex += checkContent.indexOf(sentence);
      totalSentiment += plainDoc.out(its.sentiment);
      result.push({
        sentence: sentence,
        sentenceStartIndex: startIndex,
        sentenceEndIndex: startIndex + sentence.length,
        sentenceLength: sentence.length,
        wordsCount: wordsCount,
        score: plainDoc.out(its.sentiment),
        classHighlight: classHighlight,
      });
    }

    startIndex += sentence.length + 1;
  });

  return {
    result: result,
    totalWordCount: totalWordCount,
    totalSentiment: totalSentiment / result.length,
  };
};

export const getSentenceStarterReport = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);

    let startWord = sentence.replace(/[^A-Za-z0-9'\-]/, " ").split(" ");

    var addIndex = 0;

    if (startWord.length == 1 && startWord[0].length == 0) {
      // skip
    } else {
      if (startWord[0].length == 0) {
        addIndex = 1;
      }

      if (startIndex == 0) {
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          wordsCount: startWord.length,
          stringStartIndex: startIndex + addIndex,
          stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
          word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
        });
      } else {
        startIndex += checkContent.indexOf(sentence);
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          wordsCount: startWord.length,
          stringStartIndex: startIndex + addIndex,
          stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
          word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
        });
      }
    }

    startIndex += sentence.length + 1;
  });

  return result;
};

export const getSentenceStarterNounReport = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");

  var options = {
    newline_boundaries: true,
  };

  var sentences = tokenizer.sentences(text, options);

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);
    let nounsList = getNames(sentence);
    var check = 1;

    if (nounsList.length > 0) {
      check = sentence.indexOf(nounsList[0]);
    }

    if (startIndex == 0) {
      if (check == 0) {
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          stringStartIndex: startIndex,
          stringEndIndex: startIndex + nounsList[0].length,
          word: nounsList[0].replace(/[^A-Za-z0-9'\-]/, " "),
        });
      }
    } else {
      startIndex += checkContent.indexOf(sentence);
      if (check == 0) {
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          stringStartIndex: startIndex,
          stringEndIndex: startIndex + nounsList[0].length,
          word: nounsList[0].replace(/[^A-Za-z0-9'\-]/, " "),
        });
      }
    }

    startIndex += sentence.length + 1;
  });

  return result;
};

export const getSentenceStarterIngReport = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };

  var sentences = tokenizer.sentences(text, options);

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);
    let startWord = sentence.replace(/[^A-Za-z0-9'\-]/, " ").split(" ");
    var addIndex = 0;

    if (startWord[0].length == 0) {
      addIndex = 1;
    }

    if (startWord[addIndex]) {
      var check = ingVerbs.includes(startWord[addIndex].trim().toLowerCase());

      if (startIndex == 0) {
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });
        }
      } else {
        startIndex += checkContent.indexOf(sentence);
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });
        }
      }
    }

    startIndex += sentence.length + 1;
  });

  return result;
};

export const getSentencesDialogue = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  let sentences = tokenizer.sentences(text, options);

  sentences = sentences.map((sentence) => {
    if (sentence.indexOf('"') >= 0) {
      return { sentence: sentence, type: "dialogue" };
    } else {
      return { sentence: sentence, type: "sentence" };
    }
  });
  return sentences;
};

export const getSentencesCount = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  return sentences.length;
};

export const getSentenceStarterConjunctionReport = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [],
    sentenceWise = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkCount = 0;
    var checkContent = text.substring(startIndex);
    let startWord = sentence.replace(/[^A-Za-z0-9'\-]/, " ").split(" ");
    var addIndex = 0;

    if (startWord.length == 1 && startWord[0].length == 0) {
      // skip
    } else {
      if (startWord[0].length == 0) {
        addIndex = 1;
      }

      var check = conjunctionWords.includes(
        startWord[addIndex].trim().toLowerCase()
      );

      if (startIndex == 0) {
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });

          checkCount = 1;
        }
      } else {
        startIndex += checkContent.indexOf(sentence);
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });

          checkCount = 1;
        }
      }
    }

    startIndex += sentence.length + 1;
    sentenceWise.push(checkCount);
  });

  return { result: result, sentenceWise: sentenceWise };
};

export const getSentenceStarterPronounReport = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);
    let startWord = sentence.replace(/[^A-Za-z0-9'\-]/, " ").split(" ");
    var addIndex = 0;

    if (startWord.length == 1 && startWord[0].length == 0) {
      // skip
    } else {
      if (startWord[0].length == 0) {
        addIndex = 1;
      }

      var check = pronounWords.includes(
        startWord[addIndex].trim().toLowerCase()
      );

      if (startIndex == 0) {
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });
        }
      } else {
        startIndex += checkContent.indexOf(sentence);
        if (check) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: startWord.length,
            stringStartIndex: startIndex + addIndex,
            stringEndIndex: startIndex + addIndex + startWord[addIndex].length,
            word: startWord[addIndex].replace(/[^A-Za-z0-9'\-]/, " "),
          });
        }
      }
    }

    startIndex += sentence.length + 1;
  });

  return result;
};

const checkInput = (input, words) => {
  let wordsSet = [];
  input.split(" ").map((keyword) => {
    var checkWord = keyword.replace(/[^A-Za-z0-9'\-]/, "").toLowerCase();
    var check = words.includes(checkWord);
    if (check) {
      wordsSet.push({ checkWord });
    }
  });

  return wordsSet.length;
};

export const getStickySentence = (text) => {
  text = text.replace(RegExp("“", "g"), '"');
  text = text.replace(RegExp("”", "g"), '"');
  text = text.replace(RegExp("’", "g"), "'");
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(text, options);
  var overallScore = 0;

  text = text.replace(RegExp("\n", "g"), " ");
  let result = [];
  var startIndex = 0;

  sentences.forEach((sentence) => {
    var checkContent = text.substring(startIndex);
    var output = checkInput(sentence, glueWords);
    var glueIndex = 0;

    if (output > 0 && sentence.split(" ").length >= 8) {
      glueIndex = (output * 100) / sentence.split(" ").length;
    }

    overallScore += glueIndex;

    if (glueIndex > 40) {
      if (startIndex == 0) {
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          wordsCount: sentence.split(" ").length,
          glueIndex: glueIndex,
          glueWordsCount: output,
        });
      } else {
        startIndex += checkContent.indexOf(sentence);
        result.push({
          sentence: sentence,
          sentenceStartIndex: startIndex,
          sentenceEndIndex: startIndex + sentence.length,
          sentenceLength: sentence.length,
          wordsCount: sentence.split(" ").length,
          glueIndex: glueIndex,
          glueWordsCount: output,
        });
      }
    }

    startIndex += sentence.length + 1;
  });

  overallScore = overallScore / sentences.length;

  return {
    result: result,
    count: sentences.length,
    overallScore: overallScore,
  };
};

export const getRepeatedPhrases = (content) => {
  try {
    let response = [];
    let stringIndexes = [];
    const myKeywords = rake(content, { delimiters: ["’'!?;"] });
    myKeywords.forEach((words) => {
      if (words.split(" ").length >= 2) {
        let data = searchString(content, words);
        data.filter((phrases, index) => {
          if (
            index > 0 &&
            data[index].stringStartIndex - data[index - 1].stringEndIndex < 300
          ) {
            stringIndexes.push({
              phrase: data[index - 1].word,
              stringStartIndex: data[index - 1].stringStartIndex,
              stringEndIndex: data[index - 1].stringEndIndex,
              wordCount: words.split(" ").length,
            });
            stringIndexes.push({
              phrase: phrases.word,
              stringStartIndex: phrases.stringStartIndex,
              stringEndIndex: phrases.stringEndIndex,
              wordCount: words.split(" ").length,
            });
          }
        });
      }
    });

    stringIndexes = stringIndexes.filter((val) => !response.includes(val));
    let known = new Set();
    response = stringIndexes.filter(
      (item) =>
        !known.has(item.stringStartIndex) && known.add(item.stringStartIndex)
    );
    known = new Set();
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAdverbsData = (query, user, genre) => {
  try {
    const adverbs = axios.post(apiRouts.reportWordsApi, {
      query: query,
      reportType: "Adverb",
      user_id: user.id,
      username: user.email,
      bookId: genre,
    });
    let content = query.replace(RegExp(/[^A-Za-z0-9'\-]/, "g"), " ");
    const weakAdverbs = axios.post(apiRouts.reportAdverbApi, { text: content });
    return axios.all([adverbs, weakAdverbs]);
  } catch (error) {
    console.log(error);
  }
};

export const getClichesData = (query, user, genre) => {
  try {
    let content = query.replace(RegExp(/[^A-Za-z0-9'\-]/, "g"), " ");
    return axios.post(apiRouts.reportClichesApi, { text: content });
  } catch (error) {
    console.log(error);
  }
};

export const checkWeakVerbInSentence = (query) => {
  try {
    const nlp = winkNLP(model);
    let showingVerbs = [
        "sight",
        "sighted",
        "sighting",
        "sights",
        "hear",
        "heard",
        "hearing",
        "taste",
        "tasted",
        "tasting",
        "smell",
        "smelled",
        "smelling",
        "smells",
        "touch",
        "touched",
        "touches",
        "touching",
        "watch",
        "watched",
        "watches",
        "watching",
        "see",
        "saw",
        "seeing",
        "seen",
        "notice",
        "noticed",
        "noticing",
        "feel",
        "feeling",
        "feels",
        "felt",
        "smell",
        "smelled",
        "smelling",
        "smells",
        "know",
        "knew",
        "knowing",
        "known",
      ],
      verbs = [];
    const plainText = query.replace(/<[^>]*>?/gm, "");
    const plainDoc = nlp.readDoc(plainText);
    var wordsCount = query.split(" ").length;
    plainDoc.tokens().each((token) => {
      let entity = token.parentEntity();
      let type =
        entity == undefined ? token.out(its.pos) : entity.out(its.type);
      let value = token.out(its.value);
      if (type == "VERB") {
        if (!showingVerbs.includes(value.toLowerCase())) verbs.push(value);
      }
    });
    verbs = verbs.filter(
      (r) =>
        (r.indexOf("ed") > 0 && r.indexOf("ed") + 2 == r.length) ||
        (r.indexOf("d") > 0 && r.indexOf("d") + 1 == r.length) ||
        (r.indexOf("t") > 0 && r.indexOf("t") + 1 == r.length)
    );
    if (wordsCount < 12 && verbs.length > 0) {
      return "telling";
    } else {
      return "showing";
    }
  } catch (error) {
    return "error";
  }
};

export const getShowTellData = (query) => {
  try {
    let showingVerbs = [
        "sight",
        "sighted",
        "sighting",
        "sights",
        "hear",
        "heard",
        "hearing",
        "taste",
        "tasted",
        "tasting",
        "smell",
        "smelled",
        "smelling",
        "smells",
        "touch",
        "touched",
        "touches",
        "touching",
        "watch",
        "watched",
        "watches",
        "watching",
        "see",
        "saw",
        "seeing",
        "seen",
        "notice",
        "noticed",
        "noticing",
        "feel",
        "feeling",
        "feels",
        "felt",
        "smell",
        "smelled",
        "smelling",
        "smells",
        "know",
        "knew",
        "knowing",
        "known",
      ],
      search = ["is", "was"],
      showPara = [],
      tellPara = [];

    query = query.replace(RegExp("“", "g"), '"');
    query = query.replace(RegExp("”", "g"), '"');
    query = query.replace(RegExp("’", "g"), "'");

    let para = query.split("\n");
    let result = [],
      startIndex = 0,
      tokensInfo = [];
    const nlp = winkNLP(model);

    para.forEach((p) => {
      let showCount = 0,
        tellCount = 0;
      var options = { newline_boundaries: true };
      var sentences = tokenizer.sentences(p, options);

      sentences.forEach((sentence) => {
        var checkContent = query.substring(startIndex);
        const plainText = sentence.replace(/<[^>]*>?/gm, "");
        const plainDoc = nlp.readDoc(plainText);
        var wordsCount = sentence.split(" ").length;
        let verbs = [],
          isDialogue = 0;
        plainDoc.tokens().each((token) => {
          let entity = token.parentEntity();
          let type =
            entity == undefined ? token.out(its.pos) : entity.out(its.type);
          let value = token.out(its.value);
          if (type == "PUNCT" && value == '"') isDialogue = 1;
          if (type == "VERB") {
            if (!showingVerbs.includes(value.toLowerCase())) verbs.push(value);
          }
        });

        tokensInfo = "showing";
        verbs = verbs.filter(
          (r) =>
            (r.indexOf("ed") > 0 && r.indexOf("ed") + 2 == r.length) ||
            (r.indexOf("d") > 0 && r.indexOf("d") + 1 == r.length) ||
            (r.indexOf("t") > 0 && r.indexOf("t") + 1 == r.length)
        );
        if (isDialogue == 0) {
          if (wordsCount < 12 && verbs && verbs.length > 0) {
            tokensInfo = "telling";
            tellCount += 1;
          } else if (
            wordsCount < 12 &&
            (sentence.search(new RegExp(`\\b${search[0]}\\b`, "i")) >= 0 ||
              sentence.search(new RegExp(`\\b${search[1]}\\b`, "i")) >= 0)
          ) {
            tokensInfo = "telling";
            tellCount += 1;
          } else {
            showCount += 1;
          }
        } else {
          showCount += 1;
        }

        if (startIndex == 0) {
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: wordsCount,
            isDialogue: isDialogue,
            verbs: verbs,
            type: tokensInfo,
          });
        } else {
          startIndex += checkContent.indexOf(sentence);
          result.push({
            sentence: sentence,
            sentenceStartIndex: startIndex,
            sentenceEndIndex: startIndex + sentence.length,
            sentenceLength: sentence.length,
            wordsCount: wordsCount,
            isDialogue: isDialogue,
            verbs: verbs,
            type: tokensInfo,
          });
        }

        startIndex += sentence.length + 1;
      });

      if (p.length == 0) startIndex += 1;

      if (p.trim() != "") {
        showPara.push(showCount);
        tellPara.push(tellCount);
      }
    });
    return { result: result, tellPara: tellPara, showPara: showPara };
  } catch (error) {
    console.log(error);
  }
};

export const getWordsReportData = (query, reportType, user, genre) => {
  return axios.post(apiRouts.reportWordsApi, {
    query: query,
    reportType: reportType,
    user_id: user.id,
    username: user.email,
    bookId: genre,
  });
};

export const getPOVVerbs = (content, user) => {
  const firstPerson = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "POVFirst",
  });
  const secondPerson = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "POVSecond",
  });
  const thirdPerson = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "POVThird",
  });

  return axios.all([firstPerson, secondPerson, thirdPerson]);
};

export const getDominantWords = (content, user) => {
  const safetyPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "safetyPower",
  });
  const angerPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "angerPower",
  });
  const encouragementPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "encouragementPower",
  });
  const energeticPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "energeticPower",
  });
  const fearPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "fearPower",
  });
  const forbiddenPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "forbiddenPower",
  });
  const generalPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "generalPower",
  });
  const greedPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "greedPower",
  });
  const lustPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "lustPower",
  });

  return axios.all([
    safetyPower,
    angerPower,
    encouragementPower,
    energeticPower,
    fearPower,
    forbiddenPower,
    generalPower,
    greedPower,
    lustPower,
  ]);
};

export const getIngVerbs = async (content, user) => {
  return axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Ing",
  });
};

export const getPastVerbs = async (content, user) => {
  return axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Past",
  });
};

export const getTenseVerbs = async (content, user) => {
  const pastVerbs = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Past",
  });
  const ingVerbs = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Ing",
  });
  const presentTense = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "PresentTense",
  });
  const pastTense = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "PastTense",
  });
  let futureData = await getFutureWords(content);

  let response = await axios.all([
    pastVerbs,
    ingVerbs,
    presentTense,
    pastTense,
  ]);

  response.push(futureData);

  return response;
};

const headerOptions = {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: `${getData("token_type")} ${getData("token")}`,
    "Auth-Token": `${getData("token_type")} ${getData("token")}`,
  },
};

export const getReportUsage = () => {
  const paraphrasing = axios.post(
    apiRouts.checkUsage,
    { reportType: "paraphrasing-report-v1" },
    headerOptions
  );
  const plagrism = axios.post(
    apiRouts.checkUsage,
    { reportType: "plagiarism-citation-report-v1" },
    headerOptions
  );
  const textComplete = axios.post(
    apiRouts.checkUsage,
    { reportType: "text-completion" },
    headerOptions
  );

  return axios.all([paraphrasing, plagrism, textComplete]);
};

export const getTokenCount = (content) => {
  return axios.post(
    apiRouts.checkTokenCount,
    { content: content },
    headerOptions
  );
};

export const createAnalytics = ({
  reportName,
  charCount,
  executionTime,
  book_id,
  input,
  output,
}) => {
  return axios.post(
    apiRouts.analytics,
    {
      report_name: reportName,
      char_count: charCount,
      execution_time: executionTime,
      book_id: book_id,
      input: input,
      output: output,
    },
    headerOptions
  );
};

export const getBookHistory = () => {
  return axios.post(apiRouts.get_book_history, {}, headerOptions);
}

export const fetchBookHistory = (doc_id) => {
  return axios.post(apiRouts.fetch_book_history, { doc_id: doc_id }, headerOptions);
}

export const getSelectedBookHistory = (id) => {
  return axios.post(apiRouts.get_selected_book_history, { id: id }, headerOptions);
}

// export const createAiBook = (id) => {
//   return axios.post(apiRouts.create_ai_book, { id: id }, headerOptions);
// }

export const getAiBook = () => {
  return axios.post(apiRouts.get_ai_book, {  }, headerOptions);
}

export const fetchAiBook = (doc_id) => {
  return axios.post(apiRouts.fetch_ai_book, { doc_id: doc_id }, headerOptions);
}

export const selectedAiBook = (id) => {
  return axios.post(apiRouts.selected_ai_book, { id: id }, headerOptions);
}


export const createBookHistory = ({
  user_id, 
  uuid, 
  book_title, 
  user_synopsis, 
  ai_synopsis, 
  no_variations, 
  chapter_count, 
  book_outline, 
  ai_outline, 
  genre, 
  character_details, 
  dialogues, 
  show_tell, 
  book_scene, 
  book_chapters, 
  book_stages
}) => {
  return axios.post(
    apiRouts.create_book_history,
    {
      user_id: user_id, 
      uuid: uuid, 
      book_title: book_title, 
      user_synopsis: user_synopsis, 
      ai_synopsis: ai_synopsis, 
      no_variations: no_variations, 
      chapter_count: chapter_count, 
      book_outline: book_outline, 
      ai_outline: ai_outline, 
      genre: genre, 
      character_details: character_details, 
      dialogues: dialogues, 
      show_tell: show_tell, 
      book_scene: book_scene, 
      book_chapters: book_chapters, 
      book_stages: book_stages, 
    },
    headerOptions
  );
};

export const getAnalyticsHistory = ({ book_id }) => {
  return axios.post(apiRouts.history, { book_id: book_id }, headerOptions);
};

export const getTextCompletion = (text, size) => {
  return axios.post(
    apiRouts.textCompletion,
    { text: text, size: size },
    headerOptions
  );
};

export const getAIEditor = (
  selection,
  size,
  content,
  title,
  paragraphintro,
  voicetone,
  keywords,
  sender,
  receiver,
  characters,
  plot,
  modelName,
  content_framework
) => {
  return axios.post(
    apiRouts.aiEditor,
    {
      selection: selection,
      size: size,
      content: content,
      title: title,
      paragraph_intro: paragraphintro,
      voice_tone: voicetone,
      keywords: keywords,
      sender: sender,
      receiver: receiver,
      characters: characters,
      plot: plot,
      model_name: modelName,
      content_framework: content_framework
    },
    headerOptions
  );
};

export const generateOutlineApiNonStream = (brainDump, genre, writingStyle, synopsis, charactersThings, aimodel, user_mode) => {
  return axios.post(
    apiRouts.generate_outline_non_stream,
    {
      brainDump: brainDump, 
      genre: genre, 
      writingStyle: writingStyle, 
      synopsis: synopsis, 
      charactersThings: charactersThings, 
      aimodel: aimodel, 
      user_mode: user_mode
    },
    headerOptions
  );
};

export const generateBookTitleApiNonStream = (brainDump, genre, writingStyle, synopsis, aimodel, user_mode) => {
  return axios.post(
    apiRouts.generate_book_title_non_stream,
    {
      brainDump: brainDump, 
      genre: genre, 
      writingStyle: writingStyle, 
      synopsis: synopsis, 
      aimodel: aimodel, 
      user_mode: user_mode
    },
    headerOptions
  );
};



export const generateCharactersApiNonStream = (brainDump, genre, writingStyle, synopsis, aimodel, user_mode) => {
  return axios.post(
    apiRouts.generate_character_location_non_stream,
    {
      brainDump: brainDump, 
      genre: genre, 
      writingStyle: writingStyle, 
      synopsis: synopsis, 
      aimodel: aimodel, 
      user_mode: user_mode
    },
    headerOptions
  );
};

export const getAddedTokens = (userid) => {
  return axios.post(apiRouts.addedTokens, { userid: userid }, headerOptions);
};

export const getParagraphVariation = (content) => {
  return axios.post(apiRouts.paragraphVariationApi, { content }, headerOptions);
};

export const getPacing = (content) => {
  return axios.post(apiRouts.pacingApi, { content }, headerOptions);
};

export const getStylometry = (text) => {
  return axios.post(apiRouts.stylometry, { sentence: text }, headerOptions);
};

export const getParaphrasing = (text, start, length, type) => {
  return axios.post(
    apiRouts.paraphre_openai,
    { sentence: text, start: start, length: length, type: type },
    headerOptions
  );
};

export const getParaphraseText = (text, type) => {
  return axios.post(
    apiRouts.paraphrase,
    { sentence: text, type: type },
    headerOptions
  );
};

export const getWordnetData = (content) => {
  return axios.post(apiRouts.wordnetData, { content }, headerOptions);
};

export const getPlagiarismCitation = (query) => {
  return axios.post(apiRouts.plagiarismApi, { query });
};

export const htmlPurifierPost = (docxData) => {
  return axios.post(apiRouts.htmlPurifier, { docxData });
};

export const uploadRTF = (rftData) => {
  return axios.post(apiRouts.rtfUpload, { rftData });
};

// export const uploadDOCX = (formData) => {
//   return axios.post(apiRouts.docxUpload, formData, {
//     headers: { "Content-Type": "multipart/form-data" },
//   });
// };

export const uploadDOCX = (formData) => {
  return axios.post(apiRouts.docx_html, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getPassiveVoice = (content) => {
  return axios.post(apiRouts.suggestion_report, { text: content });
};

export const getTextReadabilityScores = (content, sentenceCount, wordCount) => {
  return axios.post(
    apiRouts.textReadability,
    { content, sentenceCount, wordCount },
    headerOptions
  );
};

export const getTextReadability = (content, user, sentenceCount, wordCount) => {
  const textReadability = axios.post(
    apiRouts.textReadability,
    { content, sentenceCount, wordCount },
    headerOptions
  );
  const daleChallWords = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Readability",
  });

  return axios.all([textReadability, daleChallWords]);
};

export const getPersonalWords = async (content, type) => {
  let wordsData = await getWords(type);
  wordsData = wordsData.data.result.map((pW) => pW.word);
  if (wordsData.length > 0) {
    return searchStrArrFromContent(content, wordsData);
  } else {
    return null;
  }
};

export const getFutureWords = async (content) => {
  if (future.length > 0) {
    return searchStrArrFromContent(content, future);
  } else {
    return null;
  }
};

// export const getHomonymsWords = async (content) => {
//   return searchHomonymArrFromContent(content, homonymsWords);
// };

export const getSummaryReportData = (
  content,
  user,
  sentenceCount,
  wordCount
) => {
  const passiveIndicators = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "PassiveIndicators",
    bookId: 23,
  });
  const dialogueTag = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "DialogueTag",
    bookId: 23,
  });
  const paragraphVariation = axios.post(
    apiRouts.paragraphVariationApi,
    { content },
    headerOptions
  );

  const pacingCount = axios.post(
    apiRouts.pacingApi,
    { content },
    headerOptions
  );
  const textReadability = axios.post(
    apiRouts.textReadability,
    { content, sentenceCount, wordCount },
    headerOptions
  );
  const encouragementPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "encouragementPower",
    bookId: 23,
  });
  const angerPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "angerPower",
    bookId: 23,
  });
  const energeticPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "energeticPower",
    bookId: 23,
  });
  const fearPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "fearPower",
    bookId: 23,
  });
  const forbiddenPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "forbiddenPower",
    bookId: 23,
  });
  const generalPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "generalPower",
    bookId: 23,
  });
  const greedPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "greedPower",
    bookId: 23,
  });
  const lustPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "lustPower",
    bookId: 23,
  });
  const safetyPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "safetyPower",
    bookId: 23,
  });
  const wordFreq = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Wordfreq",
  });
  const cliches = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Cliche",
    bookId: 23,
  });

  return axios.all([
    passiveIndicators,
    dialogueTag,
    paragraphVariation,
    pacingCount,
    textReadability,
    encouragementPower,
    angerPower,
    energeticPower,
    fearPower,
    forbiddenPower,
    generalPower,
    greedPower,
    lustPower,
    safetyPower,
    wordFreq,
    cliches,
  ]);
};

export const getComparisonReportData = (content, user) => {
  const pacing = axios.post(apiRouts.pacingApi, { content }, headerOptions);
  const conjunction = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Conjunction",
    bookId: 23,
  });
  const cliches = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Cliche",
    bookId: 23,
  });
  const complex = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Complex",
    bookId: 23,
  });
  const adverbs = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Adverb",
    bookId: 23,
  });
  const dialogueTag = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "DialogueTag",
    bookId: 23,
  });
  const genericDescriptors = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "GenericDescription",
    bookId: 23,
  });
  const ing = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Ing",
  });
  const passiveIndicators = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "PassiveIndicators",
    bookId: 23,
  });
  const showingTelling = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "ShowingAndTelling",
    bookId: 23,
  });
  const unnecessaryFillers = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "Unnecessary",
    bookId: 23,
  });
  const encouragementPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "encouragementPower",
    bookId: 23,
  });
  const angerPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "angerPower",
    bookId: 23,
  });
  const energeticPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "energeticPower",
    bookId: 23,
  });
  const fearPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "fearPower",
    bookId: 23,
  });
  const forbiddenPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "forbiddenPower",
    bookId: 23,
  });
  const generalPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "generalPower",
    bookId: 23,
  });
  const greedPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "greedPower",
    bookId: 23,
  });
  const lustPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "lustPower",
    bookId: 23,
  });
  const safetyPower = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: user.id,
    username: user.email,
    reportType: "safetyPower",
    bookId: 23,
  });

  return axios.all([
    pacing,
    conjunction,
    cliches,
    complex,
    adverbs,
    dialogueTag,
    genericDescriptors,
    ing,
    passiveIndicators,
    showingTelling,
    unnecessaryFillers,
    encouragementPower,
    angerPower,
    energeticPower,
    fearPower,
    forbiddenPower,
    generalPower,
    greedPower,
    lustPower,
    safetyPower,
  ]);
};

export const getConsolidatedReportsData = async (content) => {
  let text = content.replace(/[^A-Za-z0-9'\-]/, " ");

  let sentences = await getSentenceVariation(content);
  let glueIndex = await getStickySentence(content);

  const pacingAPI = axios.post(apiRouts.pacingApi, { content }, headerOptions);
  const suggestionsAPI = axios.post(apiRouts.suggestion_report, { text: text });
  let apiData = await axios.all([pacingAPI, suggestionsAPI]);

  let conjunctionStart = await getSentenceStarterConjunctionReport(content);
  let ingStart = await getSentenceStarterIngReport(content);
  let pacing = apiData[0].data.response.filter(
    (pace) => pace.pacingCount > 120
  );
  let suggestions = apiData[1].data.msg;
  let sentenceCount = sentences.result.length;
  let paragraphs = content.split("\n");

  paragraphs = paragraphs.filter((paragraph) => paragraph.trim().length > 0);
  let lessThen10 = sentences.result.filter(
    (res) => res.wordsCount < 10 && res.wordsCount != 0
  );
  let tenTo19 = sentences.result.filter(
    (res) => res.wordsCount >= 10 && res.wordsCount <= 19
  );
  let twentyTo29 = sentences.result.filter(
    (res) => res.wordsCount >= 20 && res.wordsCount <= 29
  );
  let thirtyTo39 = sentences.result.filter(
    (res) => res.wordsCount >= 30 && res.wordsCount <= 39
  );
  let greaterThen40 = sentences.result.filter((res) => res.wordsCount >= 40);
  let passiveVoice = suggestions.filter((suggestion) =>
    suggestion.reason.includes("may be passive voice")
  );
  let stickySentenses = glueIndex.result.filter(
    (sentence) => sentence.glueIndex > 40
  );

  let totalSentiment = sentences.totalSentiment ? sentences.totalSentiment : 0;
  let averageGlueIndex = glueIndex.overallScore ? glueIndex.overallScore : 0;

  return {
    pacing: pacing,
    ingStart: ingStart,
    suggestions: suggestions,
    sentences: sentences.result,
    tenTo19: tenTo19,
    lessThen10: lessThen10,
    twentyTo29: twentyTo29,
    thirtyTo39: thirtyTo39,
    greaterThen40: greaterThen40,
    stickySentenses: stickySentenses,
    conjunctionStart: conjunctionStart.result,
    sentenceCount: sentenceCount,
    paragraphs: paragraphs.length,
    averageGlueIndex: Number(averageGlueIndex).toFixed(1),
    totalSentiment: Number(totalSentiment).toFixed(1),
    passiveVoice: passiveVoice,
  };
};

export const getParagraphWiseGrammar = async (content, thisElem) => {
  let resultArr = [];

  let paragraphData = await getParagraphVariation(content);
  let paragraphs = paragraphData.data.response;

  var start = thisElem.state.grammarCount.start;
  var end = paragraphs.length;
  if (thisElem.state.grammarCount.end) end = thisElem.state.grammarCount.end;

  for (let index = start; index < end; index += 50) {
    var paragraph = "";
    var lastIndex = index + 50;
    if (lastIndex >= paragraphs.length) lastIndex = paragraphs.length - 1;

    for (let i = index; i <= lastIndex; i++) {
      if (i == lastIndex) {
        paragraph += paragraphs[i].sentence;
      } else {
        paragraph +=
          paragraphs[i].sentence +
          " ".repeat(
            paragraphs[i + 1].sentenceStartIndex -
              paragraphs[i].sentenceEndIndex
          );
      }
    }

    const grammarAPI = axios.get(
      `https://grammarcheck.manuscripts.ai/v2/check?language=en-US&text=${encodeURIComponent(
        paragraph
      )}&level=picky`
    );
    const suggestionAPI = axios.post(apiRouts.suggestion_report, {
      text: paragraph,
    });

    let response = await axios.all([grammarAPI, suggestionAPI]);

    let matchArr = response[0];
    let suggestions = response[1];

    if (matchArr.data.matches.length > 0) {
      let highlightArr = [];
      for (const match of matchArr.data.matches) {
        resultArr.push({
          startIndex: paragraphs[index].sentenceStartIndex + match.offset,
          endIndex:
            paragraphs[index].sentenceStartIndex + match.offset + match.length,
          ruleId: match.rule.id,
          message: match.message,
          replacements: match.replacements,
          sentence: match.sentence,
          shortMessage: match.shortMessage,
          typeName: match.type.typeName,
          ruleCategory: match.rule.category,
          issueType: match.rule.issueType,
          ruleDescription: match.rule.description,
        });

        highlightArr.push({
          startIndex: paragraphs[index].sentenceStartIndex + match.offset,
          endIndex:
            paragraphs[index].sentenceStartIndex + match.offset + match.length,
          ruleId: match.rule.id,
          message: match.message,
          replacements: match.replacements,
          sentence: match.sentence,
          shortMessage: match.shortMessage,
          typeName: match.type.typeName,
          ruleCategory: match.rule.category,
          issueType: match.rule.issueType,
          ruleDescription: match.rule.description,
        });
      }

      if (highlightArr.length > 0) {
        quillContentInsertBlotForGrammar(
          thisElem,
          0,
          highlightArr,
          "startIndex",
          "endIndex",
          [
            {
              blotName: "span",
              id: `analytics-{index}`,
              classes: [`spelling-check`, `spelling-check-{ruleId}`],
              classDynamicKeys: [
                "",
                { replaceBy: "{ruleId}", replaceToKey: "ruleId" },
              ],
            },
          ],
          null
        );
      }

      window.$(".spelling-check").bind("click", function () {
        window.$(".replaceGrammarClick").unbind("click");
        window.$("#suggestionHover").html("");
        var content = window.$(this).data("reportdata");

        var html =
          "<div>" +
          "<p>" +
          content.contentIndex.issueType +
          "</p>" +
          "<p>" +
          content.contentIndex.message +
          "</p>" +
          "<div className='replaceBtnContainer'>";
        for (const replacement of content.contentIndex.replacements)
          html += `<button className="replaceGrammarClick" data-id="${content.resultData.identifier}" data-replacement="${replacement.value}">${replacement.value}</button>`;
        html += "</div></div>";
        window.$("#suggestionHover").html(html);

        window.$(".replaceGrammarClick").bind("click", function () {
          const elemId = window.$(this).data("id");
          const elemReplacement = window.$(this).data("replacement");

          replaceGrammarError(elemId, elemReplacement);
        });

        var temp_left = 0;
        var temp_top = 0;

        let rangeBounds = thisElem.state.quill.getBounds(
          content.contentStartKeyValue,
          2
        );

        window
          .$("#suggestionHover")
          .show()
          .css({
            left: rangeBounds.left + rangeBounds.width / 2 - temp_left,
            top: rangeBounds.bottom + temp_top + 140,
          });
      });
    }

    if (suggestions.data.msg.length > 0) {
      suggestions.data.msg = suggestions.data.msg.map((d) => {
        if (
          d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) ==
          " can weaken meaning"
        ) {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "weaken",
          };
        } else if (
          d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) ==
          " may be passive voice"
        ) {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "passivevoice",
          };
        } else if (
          d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) ==
          " is wordy or unneeded"
        ) {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "wordy",
          };
        } else if (
          d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) ==
          " is a weasel word and can weaken meaning"
        ) {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "weaselweaken",
          };
        } else if (
          d.reason.slice(d.reason.indexOf('" ') + 1, d.reason.length) ==
          " is a weasel word"
        ) {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "weasel",
          };
        } else {
          return {
            ...d,
            startIndex: paragraphs[index].sentenceStartIndex + d.index,
            endIndex: paragraphs[index].sentenceStartIndex + d.index + d.offset,
            highlighterClass: "others",
          };
        }
      });

      let data = _.sortBy(suggestions.data.msg, "startIndex");

      var weaken = data.filter((d) => d.highlighterClass == "weaken");
      var passivevoice = data.filter(
        (d) => d.highlighterClass == "passivevoice"
      );
      var wordy = data.filter((d) => d.highlighterClass == "wordy");
      var weaselweaken = data.filter(
        (d) => d.highlighterClass == "weaselweaken"
      );
      var weasel = data.filter((d) => d.highlighterClass == "weasel");
      var others = data.filter((d) => d.highlighterClass == "others");

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        weaken,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-weaken`, `suggestions-{reason}`],
            classDynamicKeys: [
              "",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        passivevoice,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-passivevoice`, `suggestions-{reason}`],
            classDynamicKeys: [
              "",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        wordy,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-wordy`, `suggestions-{reason}`],
            classDynamicKeys: [
              "suggestions-wordy",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        weaselweaken,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-weaselweaken`, `suggestions-{reason}`],
            classDynamicKeys: [
              "suggestions-weaselweaken",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        weasel,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-weasel`, `suggestions-{reason}`],
            classDynamicKeys: [
              "suggestions-weasel",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      quillContentInsertBlotForGrammar(
        thisElem,
        0,
        others,
        "startIndex",
        "endIndex",
        [
          {
            blotName: "span",
            id: `suggestion-content-{index}`,
            classes: [`suggestions-others`, `suggestions-{reason}`],
            classDynamicKeys: [
              "suggestions-others",
              { replaceBy: "{reason}", replaceToKey: "reason" },
            ],
          },
        ]
      );

      window
        .$(
          ".suggestions-weaken, .suggestions-passivevoice, .suggestions-wordy, .suggestions-weaselweaken, .suggestions-weasel, .suggestions-others"
        )
        .bind("mouseover", function () {
          window.$("#suggestionHover").html("");
          var content = window.$(this).data("reportdata");

          var html =
            "<div>" + "<p>" + content.contentIndex.reason + "</p>" + "</div>";
          window.$("#suggestionHover").html(html);

          var temp_left = 0;
          var temp_top = 0;

          let rangeBounds = thisElem.state.quill.getBounds(
            content.contentStartKeyValue,
            2
          );

          window
            .$("#suggestionHover")
            .show()
            .css({
              left: rangeBounds.left + rangeBounds.width / 2 - temp_left,
              top: rangeBounds.bottom + temp_top + 140,
            });
        });

      window
        .$(
          ".suggestions-weaken, .suggestions-passivevoice, .suggestions-wordy, .suggestions-weaselweaken, .suggestions-weasel, .suggestions-others"
        )
        .bind("mouseout", function (event) {
          window.$("#suggestionHover").hide();
        });
    }
  }

  return resultArr;
};

export const getAnalyticsData = async (content) => {
  content = content.replace(new RegExp("“", "g"), '"');
  content = content.replace(new RegExp("”", "g"), '"');
  content = content.replace(new RegExp("’", "g"), "'");
  content = content.replace(new RegExp("\n", "g"), " ");

  let response = [];
  var options = {
    newline_boundaries: true,
  };
  var sentences = tokenizer.sentences(content, options);
  const sentenceCount = sentences.length;
  const wordsCount = content.split(" ").length;
  var wordCount = wordsCount;

  let complexWordsCount = axios.post(apiRouts.reportWordsApi, {
    query: content,
    user_id: "",
    username: "",
    reportType: "Complex",
  });
  let textReadabilityReport = axios.post(
    apiRouts.textReadability,
    { content, sentenceCount, wordCount },
    headerOptions
  );
  let result = await axios.all([complexWordsCount, textReadabilityReport]);

  const charCount = content.replace(new RegExp(" ", "g"), "").length;
  const complexCount = result[0].data.data.length;
  const readabilityScores = result[1].data.response;

  const lexionCount = content.replace(new RegExp("^ws", "g"), "").split(" ").length;
  const gulpeaseIndex = ((300 * sentenceCount) / lexionCount - (10 * charCount) / lexionCount + 89 ).toFixed(1);
  const readingTime = ( content.replace(new RegExp(" ", "g"), "").length * 14.69 ).toFixed(0);
  const clarity = 100 - (complexCount / wordsCount) * 100;

  response = {
    sentenceCount: sentences.length,
    wordsCount: wordsCount,
    charCount: content.replace(new RegExp(" ", "g"), "").length,
    lexionCount: lexionCount,
    gulpeaseIndex: gulpeaseIndex,
    readingTime: readingTime,
    clarity: clarity,
    readabilityScores: readabilityScores,
  };

  return response;
};

export const get_synopsis = (allChapters) => {
  // return axios.post(apiRouts.get_synopsis_old, { chapters: allChapters }, headerOptions);
  return false;
};

export const updateTokenUsages = async (reportName, book_id, input, output) => {
  let content = input + " " + output;
  let res = await getTokenCount(content);
  await createAnalytics({ reportName: reportName, charCount: res.data.result.length, executionTime: 1000, book_id: book_id, input: input.trim(), output: output.trim() });
  return res.data.result.length;
};

export const createChapterGeneration = (doc_id, chapter_title, scene_beat) => {
  return axios.post(apiRouts.create_chapter_generation, { doc_id: doc_id, chapter_title: chapter_title, scene_beat: scene_beat }, headerOptions);
}

export const getChapterGeneration = (doc_id) => {
  return axios.post(apiRouts.get_chapter_generation, { doc_id: doc_id }, headerOptions);
}

export const updateChapterGeneration = (id, doc_id, chapter_title, scene_beat) => {
  return axios.post(apiRouts.update_chapter_generation, { id: id, doc_id: doc_id, chapter_title: chapter_title, scene_beat: scene_beat }, headerOptions);
}

export const getCitationsData = (searchTerm) => {
  return axios.post(apiRouts.multipleCitation, { search: searchTerm });
  // return axios.get(`https://api.openalex.org/works?search=${searchTerm}`);
}

export const getCitationsDataSingle = (searchTerm) => {
  return axios.post(apiRouts.singleCitation, { search: searchTerm });
  // return axios.get(`https://api.openalex.org/works?page=1&per_page=1&search=${searchTerm}`);
}

export const getAutoComplete = (content) => {
  return axios.post(apiRouts.autocomplete, { content: content }, headerOptions);
}

export const createCitation = (citation_id, author_display_name, user_id, title, keywords, doi_link, publication_date, publication_year, cited_by_count, source_organization_name, source_name, source_type, authors, volume_no, issue_no, first_page, last_page, data_json) => {
  return axios.post(apiRouts.create_citation, { citation_id: citation_id, author_display_name: author_display_name, user_id: user_id, title: title, keywords: keywords, doi_link: doi_link, publication_date: publication_date, publication_year: publication_year, cited_by_count: cited_by_count, source_organization_name: source_organization_name, source_name: source_name, source_type: source_type, authors: authors, volume_no: volume_no, issue_no: issue_no, first_page: first_page, last_page: last_page, data_json: data_json }, headerOptions);
}

export const getCitation = (citation_id) => {
  return axios.post(apiRouts.get_citation, { citation_id: citation_id }, headerOptions);
}

export const getAllCitations = () => {
  return axios.post(apiRouts.get_all_citation, {  }, headerOptions);
}

export const updateCitation = (citation_data) => {
  return axios.post(apiRouts.update_citation, citation_data, headerOptions);
}

export const deleteCitation = (id) => {
  return axios.post(apiRouts.delete_citation, { id: id }, headerOptions);
}

export const generateOutlineAPI = (context, genre, user_mode) => {
  return axios.post(apiRouts.outline_generator, { context: context, genre: genre, user_mode: user_mode }, headerOptions);
}

export const graphGeneration = (context) => {
  return axios.post(apiRouts.graph_generation, { context: context }, headerOptions);
}

export const highchartsExport = (options) => {
  return axios.post('https://export.highcharts.com/', { infile: JSON.stringify(options), b64: true, width: 600, constr : "Chart" }, { headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", "Access-Control-Allow-Origin": "*" }});
}

// graph_generation