const apiBaseUrl = "https://api.manuscripts.ai/api/";
const apiBaseUrlPhp = "https://phpapi.manuscripts.ai/";
const apiBaseUrlPython = "https://pythonservice.manuscripts.ai/";

export const apiRouts = {
  pacingApi: apiBaseUrlPython + "pacing",
  stylometry: apiBaseUrlPython + "stylometry",
  textReadability: apiBaseUrlPython + "textreadability",
  senteceVariation: apiBaseUrlPython + "sentence-variation",
  paragraphVariationApi: apiBaseUrlPython + "paragraph-variation",
  graph_generation: apiBaseUrlPython + "graph-generation",
  docx_html: apiBaseUrlPython + "docx-html",

  stream_voice_change_v7: apiBaseUrlPython + "stream/voice-change-v7",
  stream_paraphrase_openai_v7: apiBaseUrlPython + "stream/paraphrase-openai-v7",
  stream_perspective_openai_v7: apiBaseUrlPython + "stream/perspective-edit-v7",
  stream_rewritebook_openai_v7: apiBaseUrlPython + "stream/rewrite-v7",
  stream_ai_edit_v7: apiBaseUrlPython + "/stream/ai-edit-v7",
  stream_expand_openai_v7: apiBaseUrlPython + "stream/expand-v7",
  stream_complete_story_openai_v7: apiBaseUrlPython + "stream/complete-v7",
  stream_write_opposing_statement_openai_v7: apiBaseUrlPython + "stream/opposing-statement-v7",
  stream_content_improver_openai_v7: apiBaseUrlPython + "stream/improve-v7",
  stream_summary_gen_openai_v7: apiBaseUrlPython + "stream/summary-v7",
  stream_write_anything_openai_v2: apiBaseUrlPython + "stream/write-v2",
  improve_structure_v7: apiBaseUrlPython + "/stream/improve-structure-v7",
  ai_assistant_v2: apiBaseUrlPython + "/stream/assistant-v2",
  ai_assistant_v22: apiBaseUrlPython + "/stream/assistant-v22",
  ai_generate_scenes: apiBaseUrlPython + "/stream/ai/generate-scenes",
  ai_generate_chapter_v5: apiBaseUrlPython + "/stream/ai/generate-chapter-v5",
  autocomplete: apiBaseUrlPython + "/autocomplete",
  stream_autocomplete: apiBaseUrlPython + "/stream/autocomplete",
  outline_generator_v7: apiBaseUrlPython + "stream/outline-generator-v7",
  outline_generator: apiBaseUrlPython + "outline-generator",
  heading_generator_v7: apiBaseUrlPython + "stream/heading-generator-v7",
  write_in_depth_v7: apiBaseUrlPython + "stream/write-in-depth-v7",
  opposing_argument_v7: apiBaseUrlPython + "stream/opposing-argument-v7",
  write_introduction_v7: apiBaseUrlPython + "stream/write-introduction-v7",
  write_conclusion_v7: apiBaseUrlPython + "stream/write-conclusion-v7",
  translate_v7: apiBaseUrlPython + "stream/translate-v7",

  aiEditor: apiBaseUrlPython + "ai-editor-v2",
  ai_templates: apiBaseUrlPython + "ai-templates",
  get_synopsis_v5: apiBaseUrlPython + "stream/book-writer-v5",
  get_outline_v5: apiBaseUrlPython + "stream/outline-v5",
  get_scene_v5: apiBaseUrlPython + "stream/scene-v5",
  get_chapter_compiled: apiBaseUrlPython + "stream/chapters",
  get_chapter_expand_v5: apiBaseUrlPython + "stream/expand_chapter_v5",
  generate_synopsis: apiBaseUrlPython + "stream/generate-synopsis",
  generate_outline: apiBaseUrlPython + "stream/generate-outline",
  generate_scenes: apiBaseUrlPython + "stream/generate-scenes",
  generate_outline_non_stream: apiBaseUrlPython + "non-stream/generate-outline",
  generate_book_title_non_stream: apiBaseUrlPython + "non-stream/generate-book-title",
  generate_character_location_non_stream: apiBaseUrlPython + "non-stream/generate-character-location",
  generate_chapter: apiBaseUrlPython + "stream/generate-chapter",
  generate_writing_style: apiBaseUrlPython + "stream/generate-writing-style",
  paraphrase_output: apiBaseUrlPython + "paraphrase-output",
  get_diff: apiBaseUrlPython + "get-diff",

  books: apiBaseUrl + "v1/book",
  genres: apiBaseUrl + "v1/genre",
  profile: apiBaseUrl + "v1/user/profile",
  check_email: apiBaseUrl + "check_account",
  wordnetData: apiBaseUrl + "wordnetData",
  user_login: apiBaseUrl + "v1/auth/login",
  analytics: apiBaseUrl + "v1/user/analytics",
  history: apiBaseUrl + "v1/user/analytics/history",
  suggestion_report: apiBaseUrl + "suggestion",
  reportAdverbApi: apiBaseUrl + "adverbApi",
  reportClichesApi: apiBaseUrl + "clichesApi",
  updateTour: apiBaseUrl + "v1/user/updateTour",
  user_register: apiBaseUrl + "v1/auth/register",
  check_code: apiBaseUrl + "v1/auth/check-code",
  user_documents: apiBaseUrl + "v1/user/document",
  packages: apiBaseUrl + "v1/guest/config/packages",
  removePackage: apiBaseUrl + "v1/user/removePackage",
  user_verifyEmail: apiBaseUrl + "v1/auth/email/verify",
  user_verifyAccount: apiBaseUrl + "v1/auth/account/verify",
  user_create_document: apiBaseUrl + "v1/user/document",
  checkUsage: apiBaseUrl + "v1/user/analytics/checkUsage",
  checkTokenCount: apiBaseUrl + "v1/user/analytics/checkTokenCount",
  addedTokens: apiBaseUrl + "v1/user/profile/addedTokens",
  forgot_password: apiBaseUrl + "v1/auth/forgot/password",
  user_register_google: apiBaseUrl + "v1/auth/register_google",
  update_password: apiBaseUrl + "v1/auth/forgot/password/verify",
  user_register_code: apiBaseUrl + "v1/auth/register_user",
  user_document_view: apiBaseUrl + "v1/user/document/{documentId}",
  user_delete_document: apiBaseUrl + "v1/user/document/delete/{documentId}",
  user_create_word: apiBaseUrl + "v1/user/word",
  user_words: apiBaseUrl + "v1/user/word?type={type}",
  user_delete_word: apiBaseUrl + "v1/user/word/{wordId}",
  gutenberg_data: apiBaseUrl + "get-guternberg-data",
  bookTags: apiBaseUrl + "v1/book/{bookId}/tag",
  bookTagsByGenre: apiBaseUrl + "v1/genre/{genreId}/bookTag",
  user_get_document_view: apiBaseUrl + "v1/user/document/get/{documentId}",
  user_update_document_desc: apiBaseUrl + "v1/user/document/{documentId}",
  user_update_document_view: apiBaseUrl + "v1/user/document/update/{documentId}",
  user_update_document: apiBaseUrl + "v1/user/document/updatedocfull/{documentId}",
  user_update_document_delta: apiBaseUrl + "v1/user/document/updatedoc/{documentId}",
  get_user_setting: apiBaseUrl + "v1/user/userSettings",
  get_user_language: apiBaseUrl + "v1/user/update-language",
  create_book_history: apiBaseUrl + "v1/user/book/history/create",
  get_book_history: apiBaseUrl + "v1/user/book/history/get",
  fetch_book_history: apiBaseUrl + "v1/user/book/history/fetch",
  get_selected_book_history: apiBaseUrl + "v1/user/book/history/get/selected",
  create_highlights: apiBaseUrl + "v1/book/create",
  update_highlights: apiBaseUrl + "v1/book/update",
  delete_highlights: apiBaseUrl + "v1/book/delete",
  get_highlights: apiBaseUrl + "v1/book/get",
  get_highlight: apiBaseUrl + "v1/book/get/{id}",
  create_book_details: apiBaseUrl + "v1/book/details/create",
  update_book_details: apiBaseUrl + "v1/book/details/update",
  delete_book_details: apiBaseUrl + "v1/book/details/delete",
  get_book_details: apiBaseUrl + "v1/book/details/get",
  create_ai_book: apiBaseUrl + "v1/user/book/ai/create",
  get_ai_book: apiBaseUrl + "v1/user/book/ai/get",
  fetch_ai_book: apiBaseUrl + "v1/user/book/ai/fetch",
  selected_ai_book: apiBaseUrl + "v1/user/book/ai/get/selected",
  create_chapter_generation: apiBaseUrl + "v1/user/chapter/generation/create",
  get_chapter_generation: apiBaseUrl + "v1/user/chapter/generation/get",
  update_chapter_generation: apiBaseUrl + "v1/user/chapter/generation/update",
  create_citation: apiBaseUrl + "v1/user/citation/create",
  get_citation: apiBaseUrl + "v1/user/citation",
  get_all_citation: apiBaseUrl + "v1/user/citations/user",
  update_citation: apiBaseUrl + "v1/user/citation/update",
  delete_citation: apiBaseUrl + "v1/user/citation/delete",
  get_templates: apiBaseUrl + "v1/user/template/get",
  get_template: apiBaseUrl + "v1/user/template/get/{id}",
  campaign_package: apiBaseUrl + "v1/guest/config/packages/update",

  reportWordsApi: apiBaseUrlPhp + "reports-service/api.php",
  generate_pdf: apiBaseUrlPhp + "phpdocx/report-pdf.php",
  plagiarismApi: apiBaseUrlPhp + "plagrism/check.php",
  rtfUpload: apiBaseUrlPhp + "rtf-html/index.php",
  // docxUpload: apiBaseUrlPhp + "phpdocx-advanced/api.php",
  docxUpload: apiBaseUrlPhp + "phpdocx/api2.php",
  htmlPurifier: apiBaseUrlPhp + "htmlpurifier/index.php",
  singleCitation: apiBaseUrlPhp + "citation/single.php",
  multipleCitation: apiBaseUrlPhp + "citation/multiple.php",
};

export const uiRoutes = {
  editorPage: "/editor",
  dashboard: "/",
};
