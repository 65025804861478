import { useState } from "react"

export default function CitationDynContainer ({ userData, setShortcutOpenAutoOptions, shortcutOpenAutoOptions, addCitaionLoader, hasCitation, insertAutocompleteText, removeCitationFromQuill, regenrateCitaionLoader, regenerateAutocompleteText, setHoldAutoComplete, triggerAIAssitant, writeInDepth, opposingArguments, writeIntroduction, writeConclusion, toggleAddChapterPopup, setShowHeadingModal, setPlanDocumentModal, setSkipStepFlow, triggerClearTimeOut }) {

    const [handleCommandTools, setHandleCommandTools] = useState(false);

    const continueWriting = async () => {
        await setHoldAutoComplete(true);
        await removeCitationFromQuill();
        await setShortcutOpenAutoOptions(false);
        await triggerAIAssitant();
    }

    const writeMoreDepth = async () => {
        await setHoldAutoComplete(true);
        await removeCitationFromQuill();
        await setShortcutOpenAutoOptions(false);
        await writeInDepth();
    }

    const handleWriteIntroduction = async () => {
        await setHoldAutoComplete(true);
        await removeCitationFromQuill();
        await setShortcutOpenAutoOptions(false);
        await writeIntroduction();
    }

    const handleWriteConclusion = async () => {
        await setHoldAutoComplete(true);
        await removeCitationFromQuill();
        await setShortcutOpenAutoOptions(false);
        await writeConclusion();
    }

    const handleOpposingArguments = async () => {
        await setHoldAutoComplete(true);
        await removeCitationFromQuill();
        await setShortcutOpenAutoOptions(false);
        await opposingArguments();
    }

    // const handleToggleAddChapterPopup = async () => {
    //     await setHoldAutoComplete(true);
    //     await removeCitationFromQuill();
    //     await setShortcutOpenAutoOptions(false);
    //     await toggleAddChapterPopup();
    // }

    // const handleSetShowHeadingModal = async () => {
    //     await setHoldAutoComplete(true);
    //     await removeCitationFromQuill();
    //     await setShortcutOpenAutoOptions(false);
    //     await setShowHeadingModal(true);
    // }

    // const handleSetBookFlow = async () => {
    //     await setHoldAutoComplete(true);
    //     await removeCitationFromQuill();
    //     await setShortcutOpenAutoOptions(false);
    //     await setPlanDocumentModal(true);
    // }

    return (
        <div 
            id="citation-confirm-container" style={{ display: shortcutOpenAutoOptions ? 'block' : '' }}>
            {!shortcutOpenAutoOptions && 
                <div className="card border-0">
                    <div className="card-body p-2">
                        <button onClick={(e) => insertAutocompleteText()} className="btn theme-btn fs-10px px-2 py-1 me-2"><span className="me-2 my-auto">Accept </span>{!addCitaionLoader && <i className="mdi mdi-arrow-right-thin"></i>}
                            {addCitaionLoader && 
                                <div className="spinner-border border-bottom-0 spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                        </button>
                        <button onClick={(e) => regenerateAutocompleteText()} className="btn theme-btn4 fs-12px px-2 py-1 me-2" style={{zoom:0.9}}><span className="me-2 my-auto">Regenerate </span>{!regenrateCitaionLoader && <i className="mdi mdi-reload"></i>}
                            {regenrateCitaionLoader && 
                                <div className="spinner-border border-bottom-0 spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                        </button>
                        {hasCitation && <button className="btn theme-btn5 fs-10px px-2 py-1 me-2">Preview <i className="mdi mdi-eye"></i></button> }
                        <button onClick={(e) => { removeCitationFromQuill(false); triggerClearTimeOut(5000);}} className="btn theme-btn2 fs-10px px-2 py-1 text-center rounded">Reject <i className="mdi mdi-close"></i></button>
                    </div>
                </div>
            }
            {(handleCommandTools || shortcutOpenAutoOptions) && 
                <div className="card my-2 text-start fs-12px">
                    <ul id="student-command-tools" className="list-group list-group-flush">
                        {handleCommandTools && <li onClick={(e) => setHandleCommandTools(!handleCommandTools)} className="list-group-item list-group-item-action cursor-pointer"><span><i className="mdi mdi-arrow-left me-2"></i>Back</span></li>}
                        {shortcutOpenAutoOptions && <li onClick={(e) => setShortcutOpenAutoOptions(false)} className="list-group-item list-group-item-action cursor-pointer"><span><i className="mdi mdi-arrow-left me-2"></i>Back</span></li>}
                        <li onClick={(e) => continueWriting()} className="list-group-item list-group-item-action cursor-pointer">Continue Writing</li>
                        {userData?.user.user_mode == 'Student' && 
                            <>
                                <li onClick={(e) => handleWriteIntroduction()} className="list-group-item list-group-item-action cursor-pointer">Write Introduction</li>
                                <li onClick={(e) => handleWriteConclusion()} className="list-group-item list-group-item-action cursor-pointer">Write Conclusion</li>
                                <li onClick={(e) => handleOpposingArguments()} className="list-group-item list-group-item-action cursor-pointer">Write An Opposing Argument</li>
                                <li onClick={(e) => writeMoreDepth()} className="list-group-item list-group-item-action cursor-pointer">Write More Descriptive</li>
                            </>
                        }
                        {userData?.user.user_mode == 'Author' && 
                            <>
                                {/* <li onClick={(e) => handleToggleAddChapterPopup()} className="list-group-item list-group-item-action cursor-pointer">Generate Prose</li> */}
                                {/* <li onClick={(e) => handleSetShowHeadingModal()} className="list-group-item list-group-item-action cursor-pointer">Generate Heading</li> */}
                                <li onClick={(e) => { setSkipStepFlow(0); setPlanDocumentModal(true); }} className="list-group-item list-group-item-action cursor-pointer">Lore Book</li>
                            </>
                        }
                    </ul>
                </div>
            }
        </div>

    )
}