import { useState } from "react";

export default function QuillToolBar ({ user, aISubPanel, setAISubPanel, generateContent, searchCitation, validateImage, chartLoader, createTable }) {

    const showAIPanel = (e) => {
        e.preventDefault()
        const target = document.querySelector(".ql-tooltip")
        const rect = e.target.getBoundingClientRect()
        const list = document.querySelector("#ai_tools_list")

        if (list) {
            list.classList.add('show');
        }
    }

    return (
        <>
            <div id="toolbar" className="d-flex flex-wrap gap-2 align-items-center justify-content-start py-1">
                <ul id="ai_tools_list" className="dropdown-menu position-absolute py-1">
                    <li onMouseEnter={(e) => setAISubPanel('paraphrase')}>
                        <span className="fs-12px dropdown-item">Paraphrase<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'paraphrase' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Academically')}><span className="fs-12px dropdown-item">Academically</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Boldly')}><span className="fs-12px dropdown-item">Boldly</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Casually')}><span className="fs-12px dropdown-item">Casually</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Creatively')}><span className="fs-12px dropdown-item">Creatively</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Fluency')}><span className="fs-12px dropdown-item">Fluency</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Formaly')}><span className="fs-12px dropdown-item">Formaly</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Friendly')}><span className="fs-12px dropdown-item">Friendly</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Persuasively')}><span className="fs-12px dropdown-item">Persuasively</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Short')}><span className="fs-12px dropdown-item">Short</span></li>
                            <li onClick={(e) => generateContent(e, 'Paraphrase', 'Simply')}><span className="fs-12px dropdown-item">Simply</span></li>
                        </ul>
                    </li>
                    <li onMouseEnter={(e) => setAISubPanel('ai_edit')}>
                        <span className="fs-12px dropdown-item">AI Edit<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'ai_edit' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li onClick={(e) => generateContent(e, 'AI Edit', 'Structural')}><span className="fs-12px dropdown-item">Structural Edit</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Edit', 'Line')}><span className="fs-12px dropdown-item">Line Edit</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Edit', 'Dev')}><span className="fs-12px dropdown-item">Developmental Edit</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Edit', 'Copy')}><span className="fs-12px dropdown-item">Copy Edit</span></li>
                        </ul>
                    </li>
                    {user?.user?.user_mode == 'Author' && 
                        <>
                            <li onMouseEnter={(e) => setAISubPanel('perspective')}>
                                <span className="fs-12px dropdown-item">Perspective<i className="mdi mdi-chevron-right float-end"></i></span>
                                <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'perspective' ? 'show' : ''}`} style={{ left: '200px' }}>
                                    <li onClick={(e) => generateContent(e, 'Perspective', 'First')}><span className="fs-12px dropdown-item">First</span></li>
                                    <li onClick={(e) => generateContent(e, 'Perspective', 'Second')}><span className="fs-12px dropdown-item">Second</span></li>
                                    <li onClick={(e) => generateContent(e, 'Perspective', 'Third')}><span className="fs-12px dropdown-item">Third</span></li>
                                </ul>
                            </li>
                            <li onMouseEnter={(e) => setAISubPanel('voice')}>
                                <span className="fs-12px dropdown-item">Voice Update<i className="mdi mdi-chevron-right float-end"></i></span>
                                <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'voice' ? 'show' : ''}`} style={{ left: '200px' }}>
                                    <li onClick={(e) => generateContent(e, 'Voice Update', 'Active')}><span className="fs-12px dropdown-item">Active</span></li>
                                    <li onClick={(e) => generateContent(e, 'Voice Update', 'Passive')}><span className="fs-12px dropdown-item">Passive</span></li>
                                </ul>
                            </li>
                        </>
                    }
                    <li onMouseEnter={(e) => setAISubPanel('ai_rewrite')}>
                        <span className="fs-12px dropdown-item">AI Rewrite<i className="mdi mdi-chevron-right float-end"></i></span>
                        <ul className={`top-0 position-absolute dropdown-menu ${aISubPanel == 'ai_rewrite' ? 'show' : ''}`} style={{ left: '200px' }}>
                            <li onClick={(e) => generateContent(e, 'AI Rewrite', 'Default')}><span className="fs-12px dropdown-item">Rewrite</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Rewrite', 'Show')}><span className="fs-12px dropdown-item">Show, Don't Tell</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Rewrite', 'Descriptive')}><span className="fs-12px dropdown-item">More Descriptive</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Rewrite', 'Intensity')}><span className="fs-12px dropdown-item">Add Intensity</span></li>
                            <li onClick={(e) => generateContent(e, 'AI Rewrite', 'Tonality')}><span className="fs-12px dropdown-item">Same Tonality And Intent</span></li>
                        </ul>
                    </li>
                    <li onClick={(e) => generateContent(e, 'Expand Content with AI', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Expand Content</span></li>
                    <li onClick={(e) => generateContent(e, 'Complete Story with AI', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Complete Story</span></li>
                    {user?.user?.user_mode == "Student" &&
                        <>
                            <li onClick={(e) => generateContent(e, 'Opposing Arguments', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Opposing Arguments</span></li>
                            <li onClick={(e) => generateContent(e, 'Translate', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Translate</span></li>
                        </>
                    }
                    <li onClick={(e) => generateContent(e, 'Content Improver', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Content Improver</span></li>
                    <li onClick={(e) => generateContent(e, 'Summary Generator', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Summary Generator</span></li>
                    <li onClick={(e) => generateContent(e, 'Improve Structure', '')} onMouseEnter={(e) => setAISubPanel('')}><span className="fs-12px dropdown-item">Improve Structure</span></li>
                </ul>
                <button onClick={(e) => showAIPanel(e)} className="ql-aibtn">
                    <i className="mdi mdi-chip me-2"></i> AI Commands
                </button>
                {user?.user?.user_mode == "Student" &&
                    <button onClick={(e) => searchCitation(e)} className="ql-citebtn">
                        @ Cite Text
                    </button>
                }
                <select className="ql-size">
                    <option value="small"></option>
                    <option defaultValue></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-link"></button>
                <button className="ql-script" value="sub"></button>
                <button className="ql-script" value="super"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-clean"></button>
                {/* <button onClick={(e) => createTable(e)} className="ql-table">
                    <span className="mdi mdi-table"></span>
                </button> */}
                {user?.user?.user_mode == 'Student' && 
                    <button title-right="Generate Graph" onClick={(e) => validateImage(e)}>
                        {chartLoader && 
                            <div className="spinner-border mai-spinner spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {!chartLoader && 
                            <span className="mdi mdi-file-chart-outline"></span>
                        }
                    </button>
                }
            </div>
        </>
    )
}