import { useEffect, useState } from "react";

export default function CreateOutlineBlock({ quill, displayOutlineBlock, setDisplayOutlineBlock, showOutlineModal, setShowOutlineModal, showHeadingModal, setShowHeadingModal, planDocumentModal, setPlanDocumentModal, userData, setEditorIntroModal, setDocumentDescModal, activityType, triggerFile }) {

    const [ratio, setRatio] = useState({});

    useEffect(() => {
        const range = quill.getSelection(true);
        const content = quill.getText(0, 20);
        if (range) {
            const bounds = quill.getBounds(range);
            setRatio(bounds);
        }

        if ((content.trim()).length === 0) {
            setDisplayOutlineBlock(true)
        }

    }, [ quill ])

    return (
        <>
            <div className="outlineCreateBtnContainer" style={{ 
                // left: ratio?.left + 160 + 'px', top: ratio?.bottom + 170 + 'px', 
                display: displayOutlineBlock ? 'block' : 'none' 
            }}>
                {userData.user.user_mode == "Author" && 
                    <>
                        {activityType == "edit" &&
                            <button onClick={(e) => triggerFile(e)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-file-upload-outline me-2"></span>Import your book</button>
                        }
                        <button onClick={(e) => setPlanDocumentModal(true)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-format-list-bulleted-square me-2"></span>Story Writing Mode</button>
                        <button onClick={(e) => setDocumentDescModal(true)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-format-list-bulleted-square me-2"></span>Start with a prompt</button>
                    </>
                }
                {userData.user.user_mode == "Student" && 
                    <>
                        {activityType == "edit" &&
                            <button onClick={(e) => triggerFile(e)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-file-upload-outline me-2"></span>Import your document</button>
                        }
                        <button onClick={(e) => setShowOutlineModal(true)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-format-list-bulleted-square me-2"></span>Create Outline</button>
                        <button onClick={(e) => setDocumentDescModal(true)} className="btn theme-btn2 border fs-12px border-0 shadow-sm me-2"><span className="mdi mdi-format-header-increase me-2"></span>Start with a prompt</button>
                    </>
                }
            </div>
        </>
    );
}