import { useEffect } from "react";
import { useState } from "react";
import Button5 from "../common/Buttons/Button5";
import { deleteHighlights, getHighlights, getSynopsisGenre, submitHighlights, updateBookDetailsBrainDump, updateBookDetailsGenreStyle, updateBookDetailsOutline, updateBookDetailsOutlineScene, updateBookDetailsSceneBeats, updateBookDetailsSynopsis, updateDocumentDesc } from "../../services/documentService";
import { generateChapterApi, generateOutlineApi, generateSceneBeatApi, generateSynopsisApi, generateCharactersApi, generateWritingStyleApi, generateBookTitleApi } from "../../services/openAIService";
import { getAddedTokens, getReportUsage, getTokenCount } from "../../services/reportServices";

export default function LoreBook ({ userData, docId, currentDoc, planDocumentModal, setPlanDocumentModal, aimodel, quill, skipStepFlow, updatePlaceholder, setOutlineEditor, setSceneBeatsEditor, sceneId, loadHeadings, setBookDetailsBraindump, setBookDetailsSynopsis, setBookDetailsCharacters, bookDetailsCreativity, setBookDetailsCreativity, selectedChapterEdit, tokenPurchaseErr, setTokenPurchaseErr }) {

    const [containerId, setContainerId] = useState('braindump');
    const [stepId, setStepId] = useState(0);
    const [bookRecordId, setBookRecordId] = useState(0);
    const [brainDump, setBrainDump] = useState('');
    const [genre, setGenre] = useState('');
    const [writingStyle, setWritingStyle] = useState('');
    const [synopsis, setSynopsis] = useState('');
    const [bookTitle, setBookTitle] = useState('');
    const [errMsg, setErrMsg] = useState({});
    const [characters, setCharacters] = useState([]);
    const [characterId, setCharacterId] = useState(0);
    const [characterType, setCharacterType] = useState('');
    const [characterName, setCharacterName] = useState('');
    const [characterTags, setCharacterTags] = useState('');
    const [characterDescribe, setCharacterDescribe] = useState('');
    const [characterInfo, setCharacterInfo] = useState('');
    const [outline, setOutline] = useState([]);
    const [sceneBeat, setSceneBeat] = useState([]);
    const [sceneBeatAll, setSceneBeatAll] = useState([]);
    const [sceneBeatChapter, setSceneBeatChapter] = useState('');
    const [chapter, setChapter] = useState('');
    const [characterAddEditStatus, setCharacterAddEditStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [preLoader, setPreLoader] = useState(false);
    const [dropdownToggle, setDropdownToggle] = useState(false);

    useEffect(() => {
        setErrMsg({});
        setPreLoader(true);
        if (planDocumentModal) {
            getSynopsisGenre(currentDoc?.id)
            .then(async (response) => {
                setStepId(0)
                setContainerId("braindump")
                const details = response.data.result;

                await setBookRecordId(details.id)
                await setBrainDump(details.braindump || currentDoc?.description);
                await setBookDetailsBraindump(details.braindump || currentDoc?.description);
                await setBookDetailsCreativity(details.creativity_index);
                await setStepId(0)
                setContainerId("braindump");

                await setGenre(details.genre || '')
                await setWritingStyle(details.writing_style || '')

                await setSynopsis(details.synopsis || '')
                await setBookTitle(currentDoc?.title || '');

                let outlineArr = JSON.parse(details.outline);

                await setOutline(outlineArr || []);
                await setOutlineEditor(outlineArr || [])
                await updateCharactersList(skipStepFlow);
                if (details.scene_beats) {
                    await setSceneBeatAll(details.scene_beats);
                    await setSceneBeatsEditor(details.scene_beats);
                    await setSceneBeatChapter(sceneId);
                    let update_scene = "";
                    details.scene_beats.map((scenes) => {
                        if (scenes.key == sceneId) update_scene = scenes.sceneBeat;
                    })
                    await setSceneBeat(update_scene);
                }

                if (skipStepFlow == 4) {
                    setStepId(5)
                    setContainerId("outline")
                } else if (skipStepFlow == 6) {
                    setStepId(6)
                    setContainerId("scene_beats")
                    handleSceneBeatChapter(selectedChapterEdit, details.scene_beats)
                } else {
                    if (details.braindump && details.genre && details.writing_style && details.synopsis && outlineArr.length > 0 && details.scene_beats) {
                        setStepId(5)
                        setContainerId("scene_beats")
                    } else if (details.braindump && details.genre && details.writing_style && details.synopsis && outlineArr.length > 0) {
                        setStepId(4)
                        setContainerId("outline")
                    } else if (details.braindump && details.genre && details.writing_style && details.synopsis) {
                        setStepId(2)
                        setContainerId("synopsis")
                    } else if (details.braindump && details.genre && details.writing_style) {
                        setStepId(1)
                        setContainerId("genre_style")
                    }
                }

                setPreLoader(false);
    
            }).catch((error) => console.log(error))
        }
    }, [planDocumentModal, skipStepFlow])

    const updateCharactersList = () => {
        getHighlights(currentDoc.id)
        .then((response) => {
            setCharacters(response.data.result);
            setBookDetailsCharacters(response.data.result);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const updateContainerId = (id, step) => {
        switch (containerId) {
            case "braindump":
                if (stepId < step) {
                    if (brainDump.length < 100) {
                        setErrMsg({ 'type': 'braindump', 'message': 'Please provide atleast 100 characters details to proceed further.' });
                    } else {
                        setContainerId(id);
                        setStepId(step);
                    }
                } else {
                    setContainerId(id);
                    setStepId(step);
                }
                break;
            case "genre_style":
                if (stepId < step) {
                    if (!genre) {
                        setErrMsg({ 'type': 'genre', 'message': 'Please specify genre.' });
                    } else if (!writingStyle || writingStyle?.length < 10) {
                        setErrMsg({ 'type': 'genre_style', 'message': 'Please provide atleast 10 characters details for writing style to proceed further.' });
                    } else {
                        setContainerId(id);
                        setStepId(step);
                    }
                } else {
                    setContainerId(id);
                    setStepId(step);
                }
                break;
            case "synopsis":
                if (stepId < step) {
                    if (synopsis && synopsis.length < 100) {
                        setLoader2(false)
                        setErrMsg({ 'type': 'synopsis', 'message': 'Please provide atleast 100 characters details to proceed further.' });
                        return;
                    } else if (!bookTitle.length) {
                        setLoader2(false)
                        setErrMsg({ 'type': 'book_title', 'message': 'Please provide book title.' });
                        return;
                    } else {
                        setContainerId(id);
                        setStepId(step);
                    }
                } else {
                    setContainerId(id);
                    setStepId(step);
                }
                break;
            case "characters_things":
                setContainerId(id);
                setStepId(step);
                break;
            case "outline":
                if (stepId < step) {
                    if (!outline || outline.length <= 0) {
                        setLoader2(false)
                        setErrMsg({ 'type': 'outline', 'message': 'Please add your chapters to complete the outline.' });
                        return;
                    } else {
                        setContainerId(id);
                        setStepId(step);
                    }
                } else {
                    setContainerId(id);
                    setStepId(step);
                }
                break;
            default:
                setContainerId(id);
                setStepId(step);
                break;
        }
    }

    const updateBrainDump = (id) => {
        setLoader(true)
        setErrMsg({});
        if (!brainDump || brainDump.length < 100) {
            setLoader(false)
            setErrMsg({ 'type': 'braindump', 'message': 'Please provide atleast 100 characters details to proceed further.' });
            return;
        }

        updateBookDetailsBrainDump(bookRecordId, brainDump)
        .then((response) => {
            setContainerId(id);
            setBookDetailsBraindump(brainDump);
            setStepId(1);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    // const generateWritingStyle = () => {
    //     setLoader1(true)
    //     setErrMsg({});
    //     if (writingStyleContent.trim().length < 500) {
    //         setLoader1(false)
    //         setErrMsg({ 'type': 'writing_content', 'message': 'Please provide atleast 500 characters content to generate writing style.' });
    //         return;
    //     }

    //     generateWritingStyleApi(writingStyleContent, setLoader1, setErrMsg, setWritingStyle, currentDoc.id)
    //     .then((response) => {  })
    //     .catch((error) => {
    //         setLoader1(false)
    //         setErrMsg({ 'type': 'writing_content', 'message': 'Something went wrong, Please try again later.' });
    //         return;
    //     })
    // }

    const updateGenreStyle = (id) => {
        setLoader(true)
        setErrMsg({});
        if (!genre) {
            setLoader(false)
            setErrMsg({ 'type': 'genre', 'message': 'Please specify genre.' });
            return;
        }

        if (!writingStyle || writingStyle?.length < 10) {
            setLoader(false)
            setErrMsg({ 'type': 'genre_style', 'message': 'Please provide atleast 10 characters details for writing style to proceed further.' });
            return;
        }

        updateBookDetailsGenreStyle(bookRecordId, genre, writingStyle)
        .then((response) => {
            setContainerId(id);
            setStepId(2);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const updateSynopsis = (id) => {
        setLoader2(true)
        setErrMsg({});
        if (synopsis.length < 100) {
            setLoader2(false)
            setErrMsg({ 'type': 'synopsis', 'message': 'Please provide atleast 100 characters details to proceed further.' });
            return;
        }

        if (!bookTitle.length) {
            setLoader2(false)
            setErrMsg({ 'type': 'book_title', 'message': 'Please provide book title.' });
            return;
        }

        updateBookDetailsSynopsis(bookRecordId, synopsis)
        .then((response) => {
            updateDocumentDesc(currentDoc.uuid, bookTitle, currentDoc.description).then((response) => console.log(response)).catch((error) => console.log(error));
            setContainerId(id);
            setStepId(3);
            setLoader2(false)
            setBookDetailsSynopsis(synopsis);
        }).catch((error) => {
            setLoader2(false)
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const updateCharacters = (id) => {

        // characters.map((value, index) => {
        //     characters.filter((value));
        // })

        setErrMsg({});
        setContainerId(id);
        setStepId(4);
    }

    const generateSynopsis = (type) => {
        setTokenPurchaseErr({})

        getReportUsage()
        .then(async (usageLimit) => {

            let usage = usageLimit[0].data.result.result;
            
            if (userData.packages[0].amount <= 100) {
                usage = usageLimit[0].data.result.usage_count_day
            }
            
            let content = brainDump + genre + writingStyle;
            let input_token_data = await getTokenCount(content);
            let input_token_count = input_token_data.data.result.length;

            let addOnTokens = await getAddedTokens(userData.user.id);

            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

            if (parseInt(usage) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                setLoader1(false);
                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
            } else if (usage < totalLimit) {
                if (Number(input_token_count) + Number(usage) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                    if (!brainDump || brainDump.length < 5) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
                    } else if (!genre) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
                    } else {
                        await generateSynopsisApi(brainDump, genre, writingStyle, currentDoc.id, aimodel, setSynopsis, setLoader, setErrMsg);
                    }
                } else {
                    setLoader1(false);
                    setTokenPurchaseErr({
                        type: "warning",
                        title: "Limit exceed!",
                        message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                    });
                }
            } else {
                setLoader1(false);
                setTokenPurchaseErr({
                    type: "warning",
                    title: "Limit exceed!",
                    message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                });
            }
        }).catch((error) => setLoader1(true))
    }

    const generateBookTItle = () => {
        generateBookTitleApi(userData.user.user_mode, brainDump, genre, writingStyle, synopsis, currentDoc.id, aimodel, setBookTitle, setLoader1, setErrMsg);
    }

    const generateCharacters = () => {
        if (!brainDump || brainDump.length < 5) {
            setLoader(false);
            setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
        } else if (!genre) {
            setLoader(false);
            setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
        } else {
            generateCharactersApi(userData.user.user_mode, brainDump, genre, writingStyle, synopsis, currentDoc.id, aimodel, setCharacters, setLoader, setErrMsg, updateCharactersList)
            .then((response) => {
            }).catch((error) => {
                setLoader(false)
                setErrMsg({ 'type': 'character', 'message': 'There was some issue generating records, Please try again later.' });
                return;
            })
        }
    }

    const generateOutline = async () => {
        setLoader1(true);
        let charactersThings = '';

        characters.map((character) => {
            if (character.type == "Character") charactersThings += "Character Name: "+character.title+"\nCharacter Description: "+character.description+"\nCharacter Additional Information: "+character.notes+"\n\n"
            if (character.type == "Location") charactersThings += "Location Name: "+character.title+"\nLocation Description: "+character.description+"\nLocation Additional Information: "+character.notes+"\n\n"
            if (character.type == "Thing") charactersThings += "Thing Name: "+character.title+"\nThing Description: "+character.description+"\nThing Additional Information: "+character.notes+"\n\n"
        })

        setTokenPurchaseErr({})

        getReportUsage()
        .then(async (usageLimit) => {

            let usage = usageLimit[0].data.result.result;

            if (userData.packages[0].amount <= 100) {
                usage = usageLimit[0].data.result.usage_count_day
            }

            let content = brainDump + genre + writingStyle + synopsis + charactersThings;
            let input_token_data = await getTokenCount(content);
            let input_token_count = input_token_data.data.result.length;

            let addOnTokens = await getAddedTokens(userData.user.id);

            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

            if (parseInt(usage) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                setLoader1(false);
                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
            } else if (usage < totalLimit) {
                if (Number(input_token_count) + Number(usage) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                    if (!brainDump || brainDump.length < 5) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
                    } else if (!genre) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
                    } else {
                        await generateOutlineApi(userData.user.user_mode, brainDump, genre, writingStyle, synopsis, charactersThings, currentDoc.id, aimodel, setOutline, setLoader1, setErrMsg);
                    }
                } else {
                    setLoader1(false);
                    setTokenPurchaseErr({
                        type: "warning",
                        title: "Limit exceed!",
                        message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                    });
                }
            } else {
                setLoader1(false);
                setTokenPurchaseErr({
                    type: "warning",
                    title: "Limit exceed!",
                    message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                });
            }
        }).catch((error) => setLoader1(true))
    }

    const handleOutlineChange = (value, index) => {
        let outlineArr = [];

        outline.map((res, i) => {
            if (index == i)
                outlineArr.push(value)
            else
                outlineArr.push(res)
        })

        setOutline(outlineArr);
    }

    const updateOutline = (id) => {
        setLoader2(true);
        if (!outline || outline.length <= 0) {
            setLoader2(false)
            setErrMsg({ 'type': 'outline', 'message': 'Please add your chapters to complete the outline.' });
            return;
        }

        setErrMsg({});

        updateBookDetailsOutline(bookRecordId, outline)
        .then((response) => {
            setContainerId(id);
            setStepId(5);
            setLoader2(false);
            setOutlineEditor(outline)
        }).catch((error) => {
            setLoader2(false);
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const deleteChapterFromOutline = (index) => {
        let outlineArr = outline.filter((res, i) => { if (i != index) return res; })
        let sceneBeatArr = sceneBeatAll.filter((res, i) => { if (res?.key != index) return res; })

        updateBookDetailsOutlineScene(bookRecordId, outlineArr, sceneBeatArr)
        .then((res) => {
            setOutline(outlineArr);
            setSceneBeatAll(sceneBeatArr);
        }).catch((error) => {
            setErrMsg({ 'type': 'outline', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const addChapterToOutline = () => {
        setLoader(true);
        let outlineArr = outline;
        outlineArr.push(`Chapter ${outline.length + 1} - `);
        setErrMsg({});
        updateBookDetailsOutline(bookRecordId, outline)
        .then((response) => {
            var objDiv = document.getElementsByClassName("outline_list");
            objDiv[0].scrollTop = objDiv[0].scrollHeight;
            setOutline(outlineArr);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const backToScene = () => {
        setContainerId('scene_beats')
    }

    const generateSceneBeat = () => {
        setLoader(true);
        if (!outline[sceneBeatChapter]) {
            setLoader(false);
            setErrMsg({ 'type': 'characters_list', 'message': 'Please select chapter to generate scene beat.' });
            return;
        }

        let charactersThings = '';

        characters.map((character) => {
            if (character.type == "Character") charactersThings += "Character Name: "+character.title+"\nCharacter Description: "+character.description+"\nCharacter Additional Information: "+character.notes+"\n\n"
            if (character.type == "Location") charactersThings += "Location Name: "+character.title+"\nLocation Description: "+character.description+"\nLocation Additional Information: "+character.notes+"\n\n"
            if (character.type == "Thing") charactersThings += "Thing Name: "+character.title+"\nThing Description: "+character.description+"\nThing Additional Information: "+character.notes+"\n\n"
        })

        let currentChapter = outline[sceneBeatChapter];

        let outlineString = "";
        outline.map((res, index) => {
            outlineString += res + "\n";

            if (sceneBeatAll && sceneBeatAll.length > 0) 
                sceneBeatAll.map((v, i) => { if (v.key == index && sceneBeatChapter != index) outlineString += "\n\n" + v.sceneBeat + "\n\n"; })
        })

        setTokenPurchaseErr({})

        getReportUsage()
        .then(async (usageLimit) => {

            let usage = usageLimit[0].data.result.result;

            if (userData.packages[0].amount <= 100) {
                usage = usageLimit[0].data.result.usage_count_day
            }

            let content = brainDump + genre + writingStyle + synopsis + charactersThings + aimodel + outlineString + currentChapter;
            let input_token_data = await getTokenCount(content);
            let input_token_count = input_token_data.data.result.length;

            let addOnTokens = await getAddedTokens(userData.user.id);

            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

            if (parseInt(usage) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                setLoader(false);
                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
            } else if (parseInt(usage) < totalLimit) {

                if ((Number(input_token_count) + Number(usage)) < totalLimit) {
                    if (!brainDump || brainDump.length < 5) {
                        setLoader(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
                    } else if (!genre) {
                        setLoader(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
                    } else {
                        await generateSceneBeatApi(brainDump, genre, writingStyle, synopsis, charactersThings, currentDoc.id, aimodel, outlineString, currentChapter, setSceneBeat, setLoader, setErrMsg);
                    }
                } else {
                    setLoader(false);
                    setTokenPurchaseErr({
                        type: "warning",
                        title: "Limit exceed!",
                        message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                    });
                }
            } else {
                setLoader(false);
                setTokenPurchaseErr({
                    type: "warning",
                    title: "Limit exceed!",
                    message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                });
            }
        }).catch((error) => setLoader(true))

        setErrMsg({});
        setStepId(5);
    }

    const saveSceneBeat = async () => {
        setLoader1(true);
        if (!sceneBeat || sceneBeat.length < 50) {
            setLoader1(false);
            setErrMsg({ 'type': 'scene_beat', 'message': 'Please provide scene beat.' });
            return;
        }

        let allScenes = [], isUpdated = false;

        if (sceneBeatAll && sceneBeatAll.length > 0) {
            sceneBeatAll.map((scenes) => {
                if (scenes.key == sceneBeatChapter) {
                    allScenes.push({ key: sceneBeatChapter, sceneBeat: sceneBeat });
                    isUpdated = true;
                } else {
                    allScenes.push(scenes);
                }
            })
        }

        if (!isUpdated) {
            allScenes.push({ key: sceneBeatChapter, sceneBeat: sceneBeat });
        }

        await setSceneBeatAll(allScenes);
        await setSceneBeatsEditor(allScenes);
        await updateBookDetailsSceneBeats(bookRecordId, allScenes);
        setLoader1(false);
    }

    const updateSceneBeat = async (id) => {
        setLoader2(true);
        if (!sceneBeat || sceneBeat.length < 50) {
            setLoader2(false);
            setErrMsg({ 'type': 'scene_beat', 'message': 'Please provide scene beat.' });
            return;
        }

        if (!brainDump || brainDump.length < 5) {
            setLoader2(false);
            setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
            return;
        }

        if (!genre) {
            setLoader2(false);
            setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
            return;
        }

        let allScenes = [], isUpdated = false;

        if (sceneBeatAll && sceneBeatAll.length > 0) {
            sceneBeatAll.map((scenes) => {
                if (scenes.key == sceneBeatChapter) {
                    allScenes.push({ key: sceneBeatChapter, sceneBeat: sceneBeat });
                    isUpdated = true;
                } else {
                    allScenes.push(scenes);
                }
            })
        }

        if (!isUpdated) {
            allScenes.push({ key: sceneBeatChapter, sceneBeat: sceneBeat });
        }

        await setSceneBeatAll(allScenes);
        await setSceneBeatsEditor(allScenes);
        await updateBookDetailsSceneBeats(bookRecordId, allScenes);

        let charactersThings = '';

        characters.map((character) => {
            if (character.type == "Character") charactersThings += "Character Name: "+character.title+"\nCharacter Description: "+character.description+"\nCharacter Additional Information: "+character.notes+"\n\n"
            if (character.type == "Location") charactersThings += "Location Name: "+character.title+"\nLocation Description: "+character.description+"\nLocation Additional Information: "+character.notes+"\n\n"
            if (character.type == "Thing") charactersThings += "Thing Name: "+character.title+"\nThing Description: "+character.description+"\nThing Additional Information: "+character.notes+"\n\n"
        })

        let currentChapter = outline[sceneBeatChapter];

        await setChapter('')
        await setContainerId(id)

        let outlineString = "";
        outline.map((res, index) => {
            outlineString += res + "\n";

            if (sceneBeatAll && sceneBeatAll.length > 0) 
                sceneBeatAll.map((v, i) => { if (v.key == index) outlineString += "\n\n" + v.sceneBeat + "\n\n"; })
        })

        setTokenPurchaseErr({})

        getReportUsage()
        .then(async (usageLimit) => {

            let usage = usageLimit[0].data.result.result;

            if (userData.packages[0].amount <= 100) {
                usage = usageLimit[0].data.result.usage_count_day
            }

            let content = brainDump + genre + writingStyle + synopsis + charactersThings + aimodel + outlineString + currentChapter + sceneBeat;
            let input_token_data = await getTokenCount(content);
            let input_token_count = input_token_data.data.result.length;

            let addOnTokens = await getAddedTokens(userData.user.id);

            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

            if (parseInt(usage) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                setLoader2(false);
                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
            } else if (usage < totalLimit) {
                if (Number(input_token_count) + Number(usage) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                    await generateChapterApi(brainDump, genre, writingStyle, synopsis, charactersThings, currentDoc.id, aimodel, outlineString, currentChapter, sceneBeat, setChapter, setLoader2, setErrMsg);
                } else {
                    setLoader2(false);
                    setTokenPurchaseErr({
                        type: "warning",
                        title: "Limit exceed!",
                        message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                    });
                }
            } else {
                setLoader2(false);
                setTokenPurchaseErr({
                    type: "warning",
                    title: "Limit exceed!",
                    message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                });
            }
        }).catch((error) => setLoader2(true))

        await setErrMsg({});
        await setStepId(5);
    }

    const reGenerateChapter = async () => {
        setLoader1(true);
        let charactersThings = '';

        characters.map((character) => {
            if (character.type == "Character") charactersThings += "Character Name: "+character.title+"\nCharacter Description: "+character.description+"\nCharacter Additional Information: "+character.notes+"\n\n"
            if (character.type == "Location") charactersThings += "Location Name: "+character.title+"\nLocation Description: "+character.description+"\nLocation Additional Information: "+character.notes+"\n\n"
            if (character.type == "Thing") charactersThings += "Thing Name: "+character.title+"\nThing Description: "+character.description+"\nThing Additional Information: "+character.notes+"\n\n"
        })

        let currentChapter = outline[sceneBeatChapter];

        setTokenPurchaseErr({})

        getReportUsage()
        .then(async (usageLimit) => {

            let usage = usageLimit[0].data.result.result;

            if (userData.packages[0].amount <= 100) {
                usage = usageLimit[0].data.result.usage_count_day
            }

            let content = brainDump + genre + writingStyle + synopsis + charactersThings + outline + currentChapter + sceneBeat;
            let input_token_data = await getTokenCount(content);
            let input_token_count = input_token_data.data.result.length;

            let addOnTokens = await getAddedTokens(userData.user.id);

            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

            if (parseInt(usage) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                setLoader1(false);
                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit tomorrow again or once your subscription is renewed or you can also purchase additional tokens!" });
            } else if (usage < totalLimit) {
                if (Number(input_token_count) + Number(usage) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                    if (!brainDump || brainDump.length < 5) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide braindump to generate scene beats' });
                    } else if (!genre) {
                        setLoader1(false);
                        setErrMsg({ 'type': 'data', 'message': '*Please provide genre to generate scene beats' });
                    } else {
                        await generateChapterApi(brainDump, genre, writingStyle, synopsis, charactersThings, currentDoc.id, aimodel, outline, currentChapter, sceneBeat, setChapter, setLoader1, setErrMsg);
                    }
                } else {
                    setLoader1(false);
                    setTokenPurchaseErr({
                        type: "warning",
                        title: "Limit exceed!",
                        message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                    });
                }
            } else {
                setLoader1(false);
                setTokenPurchaseErr({
                    type: "warning",
                    title: "Limit exceed!",
                    message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
                });
            }
        }).catch((error) => setLoader1(true))

        await setErrMsg({});
        await setStepId(5);
    }

    const clearCharacterForm = () => {
        setErrMsg({});
        setCharacterId(0);
        setCharacterType('');
        setCharacterName('');
        setCharacterTags('');
        setCharacterDescribe('');
        setCharacterInfo('');
    }

    const editCharacterAddEditStatus = (character) => {
        setCharacterId(character.id);
        setCharacterType(character.type);
        setCharacterName(character.title);
        setCharacterTags(character.tags);
        setCharacterDescribe(character.description);
        setCharacterInfo(character.notes);
        setCharacterAddEditStatus(true);
    }

    const editCharacter = () => {
        setLoader1(true);
        if (!characterName) {
            setLoader1(false);
            setErrMsg({ 'type': 'character_name', 'message': 'Please select chapter to generate scene beat.' });
            return;
        }

        if (!characterType) {
            setLoader1(false);
            setErrMsg({ 'type': 'character_type', 'message': 'Please select chapter to generate scene beat.' });
            return;
        }

        setErrMsg({});
        submitHighlights(currentDoc.id, characterId, characterName, characterType, characterTags, characterDescribe, characterInfo).then((response) => {
            setLoader1(false);
            updateCharactersList();
            clearCharacterForm();
            setCharacterAddEditStatus(false);
        }).catch((error) => {
            setLoader1(false);
            setErrMsg({ 'type': 'character_type', 'message': 'Technical error, Please try again later.' });
            return;
        })
    }


    const addCharacter = () => {
        setLoader2(true);
        if (!characterName) {
            setLoader2(false);
            setErrMsg({ 'type': 'character_name', 'message': 'Please select chapter to generate scene beat.' });
            return;
        }

        if (!characterType) {
            setLoader2(false);
            setErrMsg({ 'type': 'character_type', 'message': 'Please select chapter to generate scene beat.' });
            return;
        }

        setErrMsg({});
        submitHighlights(currentDoc.id, '', characterName, characterType, characterTags, characterDescribe, characterInfo).then((response) => {
            setLoader2(false);
            updateCharactersList();
            clearCharacterForm();
            setCharacterAddEditStatus(false);
        }).catch((error) => {
            setLoader2(false);
            setErrMsg({ 'type': 'character_type', 'message': 'Technical error, Please try again later.' });
            return;
        })
    }

    const deleteCharacter = async (id) => {
        await deleteHighlights(id);
        await updateCharactersList();
        await clearCharacterForm();

    }

    const handleSceneBeatChapter = (selector, sceneBeatList) => {
        setSceneBeat('');
        setSceneBeatChapter(selector)
        if (sceneBeatList && sceneBeatList?.length > 0) {
            sceneBeatList.map((scenes) => {
                if (scenes.key == selector) {
                    setSceneBeat(scenes.sceneBeat);
                }
            })
        }

        setDropdownToggle(!dropdownToggle)
    }

    const continueWithEditor = async () => {
        setLoader2(true)
        const length = await quill.getLength();
        let ops = [];
        if (length > 0) ops.push({ "retain": length });

        const chapterTitle = outline[sceneBeatChapter]+"\n";

        ops.push({ "attributes": { "header": 1 }, "insert": chapterTitle });
        ops.push({ "retain": length + chapterTitle.length });
        ops.push({ "insert": "\n"+chapter });
        await quill.updateContents(ops, 'user');
        await setPlanDocumentModal(false);
        await updatePlaceholder();
        setLoader2(false);
        await loadHeadings();
    }

    return (
        <div className={`lorebook-container ${planDocumentModal && 'd-block'}`}>
            <div className="row m-0" style={{ height: "100vh", display: preLoader ? 'd-none' : '' }}>
                <div className={preLoader ? "loaderContainer " : "loaderContainer d-none"}>
                    <div id="sandyloader">
                        <span className="loader"></span>
                    </div>
                </div>
                <div className="col-lg-3 bg-light p-3">
                    <div className="mb-4 text-start">
                        <button onClick={(e) => setPlanDocumentModal(false)} className="btn fs-12px theme-btn2"><span className="mdi mdi-arrow-left me-3"></span>Back to Editor</button>
                    </div>
                    {tokenPurchaseErr?.title &&
                        <div className="err_code_red px-3 py-1 rounded bg-danger text-white mb-3">
                            <p className="fs-12px my-0 pt-3 pb-2"><span className="fw-semibold">{tokenPurchaseErr.title} </span></p>
                            <p className="fs-12px my-0 pb-3"><span dangerouslySetInnerHTML={{ __html: tokenPurchaseErr.message }}></span></p>
                            {userData.user.user_mode == 'Author' && 
                                <div className="d-flex gap-2 mb-3 flex-wrap">
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/?passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-plus"></i> Purchase Tokens</a>
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan/?passthrough[customer_name]=${userData.user.name}&passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Monthly</a>
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan-annual/?passthrough[customer_name]=${userData.user.name}&passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Annually</a>
                                    <span onClick={(e) => setTokenPurchaseErr({})} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded ms-auto"><i className="mdi fw-bold mdi-close"></i></span>
                                </div>
                            }
                            {userData.user.user_mode == 'Student' && 
                                <div className="d-flex gap-2 mb-3 flex-wrap">
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/?passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-plus"></i> Purchase Tokens</a>
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-plan/?passthrough[customer_name]=${userData.user.name}&passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Monthly</a>
                                    <a target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-annual-plan/?passthrough[customer_name]=${userData.user.name}&passthrough[customer_email]=${userData.user.email}`} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Annually</a>
                                    <span onClick={(e) => setTokenPurchaseErr({})} className="fs-10px fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded ms-auto"><i className="mdi fw-bold mdi-close"></i></span>
                                </div>
                            }
                        </div>
                    }
                    {errMsg?.type == "data" && 
                        <div className="err_code_red px-3 py-1 rounded bg-danger text-white mb-3">
                            <p className="fs-12px my-0 pt-3 pb-2"><span className="fw-semibold">Details Missing </span></p>
                            <p className="fs-12px my-0 pb-3"><span dangerouslySetInnerHTML={{ __html: errMsg?.message }}></span></p>
                        </div>
                    }
                    {!tokenPurchaseErr?.title && errMsg?.type != "data" && 
                        <div className="card theme-btn2 p-0 mb-3">
                            <div className="card-body text-start">
                                <p className="mb-1 fs-14px fw-medium">Lore Book Progress</p>
                                <p className="mb-2 text-muted fs-12px">({stepId}/6) Completed</p>
                                <div className="progress" style={{ height: "3px" }}>
                                    <div className="progress-bar theme-background" style={{ width: stepId / 6 * 100+`%` }} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={`card cursor-pointer mb-3 ${(containerId != "braindump" && stepId > 0) ? "theme-background text-white" : "p-0"} ${containerId == "braindump" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("braindump", 0)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                    <span className="fs-5 mdi mdi-head-snowflake-outline"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Braindump</p>
                                    <p className={`${containerId == "braindump" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>This method encourages writers to quickly write down whatever comes to mind, without worrying about grammar, structure, or coherence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card cursor-pointer mb-3 ${(containerId != "genre_style" && stepId > 1) ? "theme-background text-white" : "p-0"} ${containerId == "genre_style" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("genre_style", 1)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                    <span className="fs-5 mdi mdi-list-box-outline"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Genre & Style</p>
                                    <p className={`${containerId == "genre_style" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>The genre of a story can range from fantasy to thriller to romance, each bringing its own set of conventions and themes. In addition, the writing style you choose will further enhance the reader's experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card cursor-pointer mb-3 ${(containerId != "synopsis" && stepId > 2) ? "theme-background text-white" : "p-0"} ${containerId == "synopsis" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("synopsis", 2)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                    <span className="fs-5 mdi mdi-text-box-edit-outline"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Synopsis</p>
                                    <p className={`${containerId == "synopsis" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>By providing a synopsis, you can give readers and collaborators a quick overview of your story, helping them understand the essence of your creative vision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card cursor-pointer mb-3 ${(containerId != "characters_things" && stepId > 3) ? "theme-background text-white" : "p-0"} ${containerId == "characters_things" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("characters_things", 3)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                <span className="fs-5 mdi mdi-tooltip-account"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Characters & Things</p>
                                    <p className={`${containerId == "characters_things" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>These elements in a story are limitless, allowing writers to craft unique and engaging narratives that captivate readers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card cursor-pointer mb-3 ${(containerId != "outline" && stepId > 4) ? "theme-background text-white" : "p-0"} ${containerId == "outline" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("outline", 4)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                    <span className="fs-5 mdi mdi-format-list-group-plus"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Outline</p>
                                    <p className={`${containerId == "outline" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>A story outline can help to identify any potential plot holes or inconsistencies before diving into the actual writing process</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`card cursor-pointer mb-3 ${(containerId != "scene_beats" && stepId > 5) ? "theme-background text-white" : "p-0"} ${containerId == "scene_beats" ? 'theme-btn p-0' : 'border-secondary'}`} onClick={(e) => updateContainerId("scene_beats", 5)}>
                        <div className="card-body text-start">
                            <div className="row">
                                <div className="col-1">
                                    <span className="fs-5 mdi mdi-text-long"></span>
                                </div>
                                <div className="col-10 my-auto">
                                    <p className="mb-0 ms-2 fs-14px fw-medium">Scene Beats</p>
                                    <p className={`${containerId == "scene_beats" ? '' : 'd-none'} my-2 ms-2 fs-10px`}>This can include key plot points, character arcs, conflict resolution, and overall themes. By outlining the story, the user can organize their thoughts and ensure a cohesive and engaging storyline.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 p-3">
                    {containerId == "braindump" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className={`text-start position-relative border rounded ${errMsg?.type == "braindump" ? "border-danger" : ""}`}>
                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Braindump <span className="text-danger">{errMsg?.type == "braindump" ? "- *"+errMsg?.message : ""}</span></label>
                                    <textarea className="form-control fs-3 border-0 px-3 py-2" style={{ height: "75vh" }} value={brainDump} onChange={(e) => setBrainDump(e.target.value)} placeholder="Enter your thoughts, ideas, and inspiration for your story here. Let your creativity flow and brainstorm freely." cols="30"></textarea>
                                    <span className="light_gray_btn bg-light char-counter">Characters: {brainDump?.length}</span>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-3">
                                    <Button5 text={'Next'} onClick={(e) => updateBrainDump("genre_style")} className={'theme-btn'} loader={loader}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }
                    {containerId == "genre_style" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className="row">
                                    <div className="col">
                                        <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "genre" ? "border-danger" : ""}`}>
                                            <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Genre <span className="text-danger">{errMsg?.type == "genre" ? "- *"+errMsg?.message : ""}</span></label>
                                            <input className="form-control fs-3 border-0 px-3 py-2" type="text" placeholder="Pick your genre e.g. Thriller, Comedy" value={genre} onChange={(e) => setGenre(e.target.value)} />
                                        </div>
                                        <div className={`text-start position-relative border rounded ${errMsg?.type == "genre_style" ? "border-danger" : ""}`}>
                                            <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Writing Style <span className="text-danger">{errMsg?.type == "genre_style" ? "- *"+errMsg?.message : ""}</span></label>
                                            <textarea className="form-control fs-3 border-0 px-3 py-2" style={{ height: "60vh" }} value={writingStyle} onChange={(e) => setWritingStyle(e.target.value)} placeholder={"Enter your story writing style here. Are you a poetic wordsmith or a sharp and witty storyteller?\n\ne.g.\n\nUse more dialogues\nkeep dramaic tone"} cols="30"></textarea>
                                            <span className="light_gray_btn bg-light char-counter">Characters: {writingStyle?.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-3">
                                    <Button5 text={'Next'} onClick={(e) => updateGenreStyle("synopsis")} className={'theme-btn'} loader={loader}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }
                    {containerId == "synopsis" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "book_title" ? "border-danger" : ""}`}>
                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Book Title <span className="text-danger">{errMsg?.type == "book_title" ? "- *"+errMsg?.message : ""}</span></label>
                                    <input className="form-control fs-3 border-0 px-3 py-2" type="text" placeholder="Your Book Title will come here" value={bookTitle} onChange={(e) => setBookTitle(e.target.value)} />
                                </div>
                                <div className={`text-start position-relative border rounded ${errMsg?.type == "synopsis" ? "border-danger" : ""}`}>
                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Synopsis <span className="text-danger">{errMsg?.type == "synopsis" ? "- *"+errMsg?.message : ""}</span></label>
                                    <textarea className="form-control fs-3 border-0 px-3 py-2" style={{ height: "60vh" }} value={synopsis} onChange={(e) => setSynopsis(e.target.value)} placeholder="Enter a brief summary of your story here. Be sure to captivate the reader with a glimpse of the exciting adventures, complex characters, and unexpected plot twists that await within your narrative." cols="30" rows="10"></textarea>
                                    <span className="light_gray_btn bg-light char-counter">Characters: {synopsis?.length}</span>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="my-3 text-end">
                                    <Button5 text={'Generate Synopsis'} onClick={(e) => generateSynopsis("synopsis")} className={'theme-btn6 me-3'} loader={loader}>
                                        <span className="mdi mdi-file-refresh"></span>
                                    </Button5>
                                    {synopsis && synopsis.length > 100 &&
                                        <Button5 text={'Generate Book Title'} onClick={(e) => generateBookTItle()} className={'theme-btn6 me-3'} loader={loader1}>
                                            <span className="mdi mdi-file-refresh"></span>
                                        </Button5>
                                    }
                                    <Button5 text={'Next'} onClick={(e) => updateSynopsis("characters_things")} className={'theme-btn'} loader={loader2}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }
                    {containerId == "characters_things" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className="row mx-0" style={{ height: "80vh" }}>
                                    <input className="form-control fs-3 border-0 px-3 py-2" type="hidden" placeholder="" value={characterId} onChange={(e) => setCharacterId(e.target.value)} />
                                    <div className={`position-relative col-lg-4 ${characterAddEditStatus ? '' : 'd-none'}`}>
                                        <div className="position-relative overflow-scroll border p-3 pb-5" style={{ height: "80vh" }}>
                                            <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "character_type" ? "border-danger" : ""}`}>
                                                <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100">Type <span className="text-danger">{errMsg?.type == "character_type" ? "- *"+errMsg?.message : ""}</span></label>
                                                <select className="form-control fs-14px border-0 px-3 py-2" value={characterType} onChange={(e) => setCharacterType(e.target.value)}>
                                                    <option value="">Select Option</option>
                                                    <option value="Character">Character</option>
                                                    <option value="Location">Location</option>
                                                    <option value="Thing">Thing</option>
                                                </select>
                                            </div>
                                            <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "character_name" ? "border-danger" : ""}`}>
                                                <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100" htmlFor="chapter_name">Name <span className="text-danger">{errMsg?.type == "character_name" ? "- *"+errMsg?.message : ""}</span></label>
                                                <input className="form-control fs-14px border-0 px-3 py-2" id="chapter_name" type="text" placeholder="e.g. Samuel Doe" value={characterName} onChange={(e) => setCharacterName(e.target.value)} />
                                            </div>
                                            <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "character_tag" ? "border-danger" : ""}`}>
                                                <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100" htmlFor="chapter_tag">Tags <span className="text-danger">{errMsg?.type == "character_tag" ? "- *"+errMsg?.message : ""}</span></label>
                                                <input className="form-control fs-14px border-0 px-3 py-2" id="chapter_tag"  type="text" placeholder="e.g. Sam, mystery man" value={characterTags} onChange={(e) => setCharacterTags(e.target.value)} />
                                            </div>
                                            <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "synopsis" ? "border-danger" : ""}`}>
                                                <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100" htmlFor="chapter_description">Describe <span className="text-danger fs-5px">{errMsg?.type == "synopsis" ? "- *"+errMsg?.message : ""}</span></label>
                                                <textarea className="form-control fs-14px border-0 px-3 py-2" id="chapter_description"  value={characterDescribe} onChange={(e) => setCharacterDescribe(e.target.value)} placeholder="Provide details such as characteristics, introduction, relations" cols="30" rows="4"></textarea>
                                                <span className="light_gray_btn bg-light char-counter">Characters: {characterDescribe.length}</span>
                                            </div>
                                            <div className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "synopsis" ? "border-danger" : ""}`}>
                                                <label className="fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100" htmlFor="chapter_add_info">Additional Info <span className="text-danger">{errMsg?.type == "synopsis" ? "- *"+errMsg?.message : ""}</span></label>
                                                <textarea className="form-control fs-14px border-0 px-3 py-2" id="chapter_add_info"  value={characterInfo} onChange={(e) => setCharacterInfo(e.target.value)} placeholder="Any additional information about the character" cols="30" rows="4"></textarea>
                                                <span className="light_gray_btn bg-light char-counter">Characters: {characterInfo.length}</span>
                                            </div>
                                        </div>
                                        <div className="py-2 text-end bg-white px-2 position-absolute m-auto bg-white" style={{ width: "calc(100% - 30px)", left: "10px", right: "5px", bottom: "1px" }}>
                                            {characterId > 0 && 
                                                <Button5 text={'Edit'} onClick={(e) => editCharacter() } className={'theme-btn6 me-2'} loader={loader1}>
                                                    <span className="mdi mdi-progress-check"></span>
                                                </Button5>
                                            }
                                            {characterId <= 0 && 
                                                <Button5 text={'Add'} onClick={(e) => addCharacter()} className={'theme-btn6 me-2'} loader={loader2}>
                                                    <span className="mdi mdi-progress-check"></span>
                                                </Button5>
                                            }
                                            <button onClick={(e) => { clearCharacterForm(); setCharacterAddEditStatus(false); }} className="btn theme-btn2 fs-12px me-2"><span className="me-2 mdi mdi-close"></span>Close</button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="overflow-scroll px-2" style={{ height: "80vh" }}>
                                            {characters.length == 0 &&
                                                <div className="bg-light d-flex end-0 p-3 position-absolute rounded align-items-centers" style={{ bottom: '120px', marginRight: '160px' }}>
                                                    <span className="me-2">Manage your characters, locations and things here!</span>
                                                    <span className="fs-1 mdi mdi-arrow-down-right"></span>
                                                </div>
                                            }
                                            {characters && characters.length > 0 && characters.map((character, key) => (
                                                <div key={`character-${key}`} className={`text-start position-relative border rounded-sm mb-4`}>
                                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">{character.type}</label>
                                                    <input className="form-control fs-3 border-0 px-3 py-2 rounded-0 bg-white" disabled type="text" placeholder="e.g. Samuel Doe" value={character.title} />
                                                    <div className="btn-container">
                                                        <button onClick={(e) => { editCharacterAddEditStatus(character); }} className="btn theme-btn4 fs-10px py-1 px-2 me-2"><span className="mdi mdi-circle-edit-outline me-2"></span>Edit {character.type}</button>
                                                        <button onClick={(e) => { deleteCharacter(character.id); }} className="btn theme-btn8 fs-10px py-1 px-2 me-2"><span className="mdi mdi-delete-circle me-2"></span>Delete {character.type}</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-2">
                                    <Button5 text={'Generate Using AI'} onClick={(e) => generateCharacters()} className={'theme-btn6 me-3'} loader={loader}>
                                        <span className="mdi mdi-file-refresh"></span>
                                    </Button5>
                                    <button onClick={(e) => { clearCharacterForm(); setCharacterAddEditStatus(true); }} className="btn theme-btn2 fs-14px ms-auto me-3">Add New <span className="ms-2 mdi mdi-plus-circle"></span></button>
                                    <button onClick={(e) => updateCharacters("outline")} className="btn theme-btn fs-14px ms-auto">Next <span className="ms-2 mdi mdi-arrow-right"></span></button>
                                </div>
                            </div>
                        </div>
                    }
                    {containerId == "outline" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className="overflow-scroll outline_list px-2" style={{ height: "80vh" }}>
                                    {outline.length == 0 &&
                                        <div className="bg-light d-flex end-0 p-3 position-absolute rounded align-items-centers" style={{ bottom: '120px', marginRight: '190px' }}>
                                            <span className="me-2">Add your chapter to outline or generate one using AI!</span>
                                            <span className="fs-1 mdi mdi-arrow-down-right"></span>
                                        </div>
                                    }
                                    {outline != '' && outline?.length > 0 && outline.map((res, index) => (
                                        <div key={`outline-${index}`} className={`text-start position-relative border rounded mb-4 ${errMsg?.type == "outline" ? "border-danger" : ""}`}>
                                            <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Chapter {index + 1} <span className="text-danger">{errMsg?.type == "outline" ? "- *"+errMsg?.message : ""}</span></label>
                                            <input className="form-control fs-3 border-0 px-3 py-2" type="text" placeholder="" value={res} onChange={(e) => handleOutlineChange(e.target.value, index)}/>
                                            <div className="btn-container">
                                                <button onClick={(e) => deleteChapterFromOutline(index)} className="btn theme-btn8 fs-10px py-1 px-2 me-2"><span className="mdi mdi-delete-circle me-2"></span>Delete Chapter</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-3">
                                    <Button5 text={'Add Chapter'} onClick={(e) => addChapterToOutline()} className={'theme-btn2 me-3'} loader={loader}>
                                        <span className="mdi mdi-playlist-plus"></span>
                                    </Button5>
                                    <Button5 text={'Generate Outline'} onClick={(e) => generateOutline("outline")} className={'theme-btn6 me-3'} loader={loader1}>
                                        <span className="mdi mdi-file-refresh"></span>
                                    </Button5>
                                    <Button5 text={'Next'} onClick={(e) => updateOutline("scene_beats")} className={'theme-btn'} loader={loader2}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }
                    {containerId == "scene_beats" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className="dropdown show mb-4">
                                    <div onClick={(e) => setDropdownToggle(!dropdownToggle)} className={`text-start position-relative border rounded ${errMsg?.type == "characters_list" ? "border-danger" : ""}`}>
                                        <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Selected chapter <span className="text-danger">{errMsg?.type == "characters_list" ? "- *"+errMsg?.message : ""}</span></label>
                                        <input className="form-control fs-3 border-0 px-3 py-2 cursor-pointer" readOnly={true} type="text" placeholder="Select your chapter to generate scene beats" value={outline[sceneBeatChapter]} onChange={(e) => handleSceneBeatChapter(e.target.value, sceneBeatAll)} />
                                    </div>
                                    <div className={`dropdown-menu ${dropdownToggle ? 'show' : ''}`}>
                                        {outline != '' && outline.length > 0 && outline.map((res, key) => (
                                            <span onClick={(e) => handleSceneBeatChapter(key, sceneBeatAll)} className="dropdown-item cursor-pointer rounded-0">{res}</span>
                                        ))}
                                    </div>
                                </div>
                                {/* <div className={`text-start position-relative border rounded-sm mb-4 ${errMsg?.type == "characters_list" ? "border-danger" : ""}`}>
                                    <select value={sceneBeatChapter} onChange={(e) => handleSceneBeatChapter(e.target.value, sceneBeatAll)}>
                                        <option value="">Select Chapter</option>
                                        {outline != '' && outline.length > 0 && outline.map((res, key) => (
                                            <option value={key}>{res}</option>
                                        ))}
                                        <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Select your chapter <span className="text-danger">{errMsg?.type == "characters_list" ? "- *"+errMsg?.message : ""}</span></label>
                                    </select>
                                </div> */}
                                <div className={`text-start position-relative border rounded ${errMsg?.type == "scene_beat" ? "border-danger" : ""}`}>
                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">Scene Beat <span className="text-danger">{errMsg?.type == "scene_beat" ? "- *"+errMsg?.message : ""}</span></label>
                                    <textarea className="form-control fs-3 border-0 px-3 py-2" style={{ height: "60vh" }} value={sceneBeat} onChange={(e) => setSceneBeat(e.target.value)} placeholder="Enter the beats for each scene in this chapter. Each beat should be a significant moment or turning point in the story. Let your creativity flow and bring each scene to life with your words!" cols="30"></textarea>
                                    <span className="light_gray_btn bg-light char-counter">Characters: {sceneBeat?.length}</span>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-3">
                                    <Button5 text={'Generate Scene Beats'} onClick={(e) => generateSceneBeat()} className={'theme-btn6 me-3'} loader={loader}>
                                        <span className="mdi mdi-file-refresh"></span>
                                    </Button5>
                                    <Button5 text={'Save Scene Beats'} onClick={(e) => saveSceneBeat()} className={'theme-btn2 me-3'} loader={loader1}>
                                        <span className="mdi mdi-progress-check"></span>
                                    </Button5>
                                    <Button5 text={'Generate Chapter'} onClick={(e) => updateSceneBeat('chapter')} className={'theme-btn'} loader={loader2}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }

                    {containerId == "chapter" && 
                        <div className="card border-0">
                            <div className="card-body px-0">
                                <div className={`text-start position-relative border rounded border-0 ${errMsg?.type == "chapter" ? "border-danger" : ""}`}>
                                    <label className="fs-14px fw-medium px-3 py-2 rounded-top bg-light w-100">{outline[sceneBeatChapter]}<span className="text-danger">{errMsg?.type == "chapter" ? "- *"+errMsg?.message : ""}</span></label>
                                    <textarea className="form-control fs-3 border-0 px-3 py-2" style={{ height: "75vh" }} value={chapter} onChange={(e) => setChapter(e.target.value)} placeholder="Start crafting your chapter" cols="30"></textarea>
                                    <span className="light_gray_btn bg-light char-counter">Characters: {chapter?.length}</span>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <div className="text-end my-3">
                                    <Button5 text={'Update Scene Beats'} onClick={(e) => backToScene()} className={'theme-btn6 me-3'} loader={loader}>
                                        <span className="mdi mdi-arrow-left"></span>
                                    </Button5>
                                    <Button5 text={'Re-Generate Chapter'} onClick={(e) => reGenerateChapter()} className={'theme-btn2 me-3'} loader={loader1}>
                                        <span className="mdi mdi-file-refresh"></span>
                                    </Button5>
                                    <Button5 text={'Add Chapter to Editor'} onClick={(e) => continueWithEditor()} className={'theme-btn'} loader={loader2}>
                                        <span className="mdi mdi-arrow-right"></span>
                                    </Button5>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}