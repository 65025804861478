import React, { useEffect, useState } from "react";
import { updateCampaignPackage } from "../services/documentService";
import logo from "./../assets/logo.svg";
import Button5 from "./common/Buttons/Button5";

const RedeemTokens = () => {

    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const emailParam = search.get("email");
        setEmail(emailParam || '')
    }, [])

    const checkEmail = () => {
        setErr('');
        setLoader(!loader);
        updateCampaignPackage(email)
        .then((response) => {
            setLoader(false);
            window.location.href = "/";
        }).catch((error) => {
            if (error.response?.data?.error?.email) {
                setErr(error.response?.data?.error?.email);
            } else {
                setErr('Network error occured, Try Again Later');
            }
            setLoader(false);
        })
    }

    return (
        <div className="container-fluid" style={{ background: 'rgb(247, 247, 245)', height: '100vh' }}>
            <div className="row my-auto">
                <div className="col-lg-4 mx-auto" style={{ marginTop: '15%' }}>
                    <div className="card border-0 shadow-sm">
                        <div className="card-body p-4">
                            <img src={logo} className="img-fluid mb-4 mt-2" alt="" />
                            <h5 className="mb-2">Claim Your Additional Tokens Now!</h5>
                            <p className="fs-12px mb-1 fw-normal">Provide your registered email address and avail your tokens now.</p>
                            <span className="text-danger fw-medium fs-10px ms-1">{err}</span>
                            <div className="text-start position-relative border rounded my-3">
                                <label className={`fs-10px fw-medium px-3 py-2 rounded-top bg-light w-100 ${err && 'text-danger'}`}>Email Address</label>
                                <input placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} type="text" className={`form-control fw-medium fs-14px border-0 px-3 py-2 lh-base ${err && 'is-invalid'}`} />
                            </div>
                            <Button5 text={'Get Free Tokens'} onClick={(e) => checkEmail()} className={'theme-btn6 my-2 w-100'} loader={loader}>
                                <span className="mdi mdi-arrow-right"></span>
                            </Button5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedeemTokens;