import { deltaToMarkdown } from 'quill-delta-to-markdown';
import markdownToDelta from "markdown-to-quill-delta";

export function converter (delta) {
    return new Promise((resolve, reject) => {
        try {
            const markdown = deltaToMarkdown(delta.ops);
            resolve(markdown);
        } catch (error) {
            reject(error);
        }
    });
}

export function markdown_converter (markdown) {
    return new Promise((resolve, reject) => {
        try {
            const ops = markdownToDelta(markdown);
            resolve(ops);
        } catch (error) {
            reject(error);
        }
    });
}